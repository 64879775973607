import React from 'react'
import Matches from '../../components/Dashboard/matches';
import listhoc from '../../hocs/listhoc';
import { dashboard_url, RECOM_MATCHES, SEARCH_MATCHES, SIMILAR_MATCHES } from '../../redux/actions/constants';
import DashMatches from './dashmatches';
import ActvMatches from './actvmatches';
import RequestMatches from './reqmatches';
import ReciMatches from './recimatches';

const DashBoardMatch = ({ size = 6, ...restProps }) => (
    <div className={`col-md-${size}`}>
        <Matches baseId={restProps.id} {...restProps} />
    </div>
)

const RecMatch = props => (
    <div className="col-md-12">
        <Matches baseId={props.id} {...props} />
    </div>
)

const SimMatch = (props) => <DashBoardMatch size={12} {...props} />

export const SearchMatch = listhoc(DashBoardMatch, SEARCH_MATCHES, 'search_matches', null);
export const SimilarMatch = listhoc(SimMatch, SIMILAR_MATCHES, 'similar_matches', null);
export const SearchRecom = listhoc(RecMatch, RECOM_MATCHES, 'recom_matches', dashboard_url + `?type=${1}`);


export const dashtabs = [
    {
        path: "/",
        exact: true,
        component: () => <DashMatches type={1} />
    },
    {
        path: "/new-matches",
        component: () => <DashMatches type={2} />
    },
    {
        path: "/all-matches",
        component: () => <DashMatches type={3} />
    },
    {
        path: "/number-viewed",
        component: () => <ActvMatches type={1} />
    }, {
        path: "/profile-viewed",
        component: () => <ActvMatches type={2} />
    }, {
        path: "/rejected-profiles",
        component: () => <ActvMatches type={3} />
    }, {
        path: "/shortlisted-profiles",
        component: () => <ActvMatches type={4} />
    }, {
        path: "/viewed-my-profile",
        component: () => <ActvMatches type={10} />
    }, {
        path: "/viewed-my-number",
        component: () => <ActvMatches type={11} />
    }, {
        path: "/shortlist-my-profile",
        component: () => <ActvMatches type={12} />
    }, {
        path: "/sent-requests",
        component: () => <RequestMatches  />
    }, {
        path: "/recieved-requests",
        component: () => <ReciMatches  />
    }
]; 