import React from 'react'
import { connect } from 'react-redux'

const CurrentPlan = ({ packages }) => {
    if (packages && packages.package) {
        return (
            <div className="bg-light">
                <div className="d-flex flex-wrap justify-content-center pt-3 pb-3">
                    <span className="">Package Name: <b>{packages.package.name}</b></span>
                    <span className="pl-4 pr-4">Remaining Contacts to View: <b>{packages.points}</b></span>
                    <span className="">Expiry Date: <b>{new Date(packages.expiration_on).toLocaleDateString('en-IN', {
                        year: 'numeric', month: 'long', day: 'numeric'
                    })}</b></span>
                </div>
            </div>
        )
    }
    return null;
}

const mapStateToProps = ({ user }) => ({
    packages: user.packages
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlan)
