import { _FETCHING, _FULFILLED, _REJECTED, _CLEAR, MATCH_DETAIL, SHORT_LIST_MATCH, REJECT_MATCH, LIST_NOTI, PHONE_MATCHES, INTEREST_MATCHES, SEARCH_MATCHES, RECENT_MATCHES,  NUMBER_REQUEST_MATCHES, PHOTO_REQUEST_MATCHES,  DASH_MATCHES, ACTV_MATCHES, SENT_MATCHES, RECIEVED_MATCHES, RECOM_MATCHES, _SET_ACTIVE, ACCEPT_DENY, SIMILAR_MATCHES } from "../actions/constants";
import { httpResultsReducer, httpArrayReducer } from "./httpreducer";
import creatematches, { createMatchList } from "./creatematch";

//intial match detail
const matchDetailInitial = {
    fetching: false, data: {}, error: false
};

export const matchDetail = (state = matchDetailInitial, action) => {
    switch (action.type) {
        case `${MATCH_DETAIL}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${MATCH_DETAIL}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                data: action.payload
            }
        case `${MATCH_DETAIL}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case `${SHORT_LIST_MATCH}${_FULFILLED}`:
            return {
                ...state,
                data: {
                    ...state.data,
                    is_shortlist: state.data.id && state.data.id === action.payload.id && action.payload.is_shortlist,
                    is_ignore: state.data.id === action.payload.id && action.payload.is_ignore
                }
            }
        case `${REJECT_MATCH}${_FULFILLED}`:
            return {
                ...state,
                data: {
                    ...state.data,
                    is_shortlist: state.data.id && state.data.id === action.payload.id && action.payload.is_shortlist,
                    is_ignore: state.data.id === action.payload.id && action.payload.is_ignore
                }
            }
        case `${MATCH_DETAIL}${_CLEAR}`:
            return { ...state, ...matchDetailInitial }
        default:
            return state
    }
}

//create matches with id
const matchWithId = (name) => ( state = { id: null }, action ) => {
    if(action.type === name + _SET_ACTIVE) {
        return {
            ...state,
            id: action.id
        }
    }
    if(
        action.id && 
        (
            action.type.indexOf(name) > -1 ||
            [`${SHORT_LIST_MATCH}${_FULFILLED}`, `${REJECT_MATCH}${_FULFILLED}`, `${ACCEPT_DENY}${_FULFILLED}`]
            .includes(action.type)
        )
    ) {
        return {
            ...state,
            [action.id]: creatematches(name)(state[action.id], action)
        }
    }
    return state
}


//list dash Matches
export const dash_matches = matchWithId(DASH_MATCHES);

//list actv Matches
export const actv_matches = matchWithId(ACTV_MATCHES);

//list req
export const sent_matches = matchWithId(SENT_MATCHES);
export const recieved_matches = matchWithId(RECIEVED_MATCHES);

//list notifications
export const list_noti = httpResultsReducer(LIST_NOTI);

//dashboard matches
export const recent_matches = httpArrayReducer(RECENT_MATCHES);

//manage macthes
export const phone_matches = creatematches(PHONE_MATCHES);
export const int_matches = creatematches(INTEREST_MATCHES);
export const nu_re_matches = creatematches(NUMBER_REQUEST_MATCHES);
export const ph_re_matches = creatematches(PHOTO_REQUEST_MATCHES);

//search matches
export const search_matches = creatematches(SEARCH_MATCHES);
export const recom_matches = creatematches(RECOM_MATCHES);

export const similar_matches = createMatchList(SIMILAR_MATCHES);