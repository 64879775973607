import React from 'react'
import LoadDataHoc from '../../hocs/loaddatahoc';
import { LIST_PACKAGES, packages_url, RAZOR_PAY } from '../../redux/actions/constants';
import DynamicList from '../../components/dynamiclist';
import Package from '../../components/upgrade/package';
// import AddOn from '../../components/upgrade/addon';
import Order from './order';
import ClearForm from '../Form/clearForm';

const Packages = ({ apiData }) => {
    return (
        <ClearForm
            clear={[RAZOR_PAY]}
        >
            <div>
                <div className="container pt-3 pb-3">
                    <div className="pt-3 pb-2">
                        <h3 className="text-center">Choose Your <span className="text-striked">Package</span> Partner</h3>
                    </div>
                    <div className="row">
                        <DynamicList
                            RenderItem={Package}
                            fetching={apiData.fetching}
                            data={apiData.packages}
                            error={apiData.error}
                            title="packages"
                        />
                    </div>
                </div>
                {/* <div className="container pt-3 pb-3">
                    <div className="pt-3 pb-3 text-center">
                        <h3 className="mb-0">Add-ons</h3>
                        <p>Extend your capabilities</p>
                    </div>
                    <div className="row">
                        <DynamicList
                            RenderItem={AddOn}
                            fetching={apiData.fetching}
                            data={apiData.add_on}
                            error={apiData.error}
                            title="addons"
                        />
                    </div>
                </div> */}
                {apiData.packages.length > 0 && <Order />}
            </div>
        </ClearForm>
    )
}

export default LoadDataHoc(Packages, LIST_PACKAGES, 'list_packages', packages_url);
