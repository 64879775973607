import React from 'react'
import landingbg from "../../assets/img/landingbg-crop.jpg"
import SignUp from '../../containers/Landing/signup';

const BannerSection = () => (
    <div className="pb-lg-5 landingbg">
        <img className="landing-img" src={landingbg} alt="" />
        <div className="container">
            <div className="row">
                <div className="col-lg-8" />
                <div className="col-lg-4">
                    <div className="pt-lg-5">
                        <SignUp />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default BannerSection
