import React from 'react'
import { SearchMatch } from '../../containers/Dashboard/matches';
import { search_url } from '../../redux/actions/constants';
import { withRouter } from "react-router-dom"

const SearchResultsPage = ({ location }) => {
    return (
        <div className="row mt-3">
            <div className="col-lg-9">
                <SearchMatch url={search_url + location.search} />
            </div>
        </div>
    )
}

export default withRouter(SearchResultsPage)
