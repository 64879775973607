import React from 'react'

export const init = {
    "age": "Age",
    "height": "Height",
    "religion": "Religion",
    "caste": "Caste",
    "sub_caste": "Sub caste",
    "marital_status": "Martial Status" ,
    "mother_tongue": "Mother Tongue",
    "country": "Citizenship",
    "education": "Education",
    "employed_in": "Employed in",
    "occupation": "Occupation",
    "star": "Star",
    // "raasi": "Raasi",
    "dosham": "Dosham",
    "food_habits": "Food Habits",
    "smoking_habits": "Smoking Habits",
    "drinking_habits": "Drinking Habits",
};

const Tscore = (matches) => {
    const tscore = [];
    for(let key in init){
        tscore.push(
            <div className="partner-match" key={key}>
                <p>{init[key]}</p>
                {
                    matches.includes(key) ?
                    <p className="color-green"><i className="fas fa-check-circle"></i></p>:
                    <p className="color-red"><i className="fas fa-times-circle"></i></p>
                }
            </div>
        )
    }
    return tscore;
}

export default Tscore
