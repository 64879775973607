import React from 'react'
import RegisterLayout from './registerlayout';
import RegisterForm from '../../containers/Register/registerform';

const RegisterPage = () => (
    <RegisterLayout
        heading={
            <span>We know its hard to fill up the form.<br />But Trust us, You will get the best out of it</span>
        }
    >
        <RegisterForm />
    </RegisterLayout>
)

export default RegisterPage