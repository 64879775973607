import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from '../Register/formbox';
import CustomBtn from '../../components/Buttons/CustomBtn';
import { QUICK_SEARCH_FORM } from '../../redux/actions/constants';
import { reduceForm, filterPostData, toQuery } from '../../utils/Forms/validation';

export class QuickSearchform extends Component {

    handleClick = () => {
        if(this.props.quick_search_form.length > 0){
            let searchData = filterPostData(reduceForm(this.props.quick_search_form));
            if(searchData.age){
                searchData["max_age"] = searchData.age.max;
                searchData["min_age"] = searchData.age.min;
                delete searchData.age
            }
            return toQuery(searchData);
        }
        return '';
    }

    render() {
        const searchQuery = `/search/results${this.handleClick()}`;
        return (
            <div className="col-lg-8 mb-3">
                <Formbox
                    inputsName="quick_search_form"
                    title="Quick Search"
                    fullWidth
                    formName={QUICK_SEARCH_FORM}
                />
                <div>
                    <Center>
                        <CustomBtn to={searchQuery} className="registerbtn">
                            Search
                        </CustomBtn>
                    </Center>
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ user, quick_search_form }) => ({
    user, quick_search_form
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchform)