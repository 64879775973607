import React, { Component } from "react";
import { connect } from "react-redux";
import List from "../../components/list";
import { SIGNUP_FORM, SIGNUP } from "../../redux/actions/constants";
import { signUpSubmit } from "../../redux/actions/user";
import { setFormErr, setFormValue } from "../../redux/actions/formactions";
import { validate, reduceForm } from "../../utils/Forms/validation";
import LoadingBtn from "../../components/Buttons/loadingbtn";
import SignupIp from "../../components/Form/signupip";
import FormCon from "../Form/formcon";
import { openModal } from "../../redux/actions/common";
import { Link } from "react-router-dom";

class SignUp extends Component {
    formName = SIGNUP_FORM;

    state = {
        terms: false
    };

    handleSubmit = e => {
        e.preventDefault();
        const { setFormErr, list, signUpSubmit } = this.props;
        const setErr = payload => setFormErr(this.formName, payload);
        const isErr = validate(list, setErr);
        if (isErr) {
            return;
        }
        const {
            first_name,
            last_name,
            email,
            username,
            password,
            profile_for_id,
            gender,
            mother_tongue_id,
            country_id,
            religion_id,
            caste_id
        } = reduceForm(list);
        signUpSubmit({
            user: {
                first_name,
                last_name,
                email,
                username,
                password
            },
            profile_for_id,
            gender,
            mother_tongue_id,
            country_id,
            religion_details: {
                religion_id,
                caste_id
            },
            client: Math.random() * 100000
        });
    };

    componentDidUpdate(prevProps) {
        const male = [2, 4];
        const female = [3, 5];
        const { setFormValue, list } = this.props;
        //set gender value funtion
        const setGender = data =>
            setFormValue(this.formName, {
                name: "gender",
                data
            });
        //set as male or female
        const setMale = isMale => {
            const label = isMale ? "Male" : "Female";
            setGender({
                value: {
                    label,
                    value: label
                },
                isDisabled: true
            });
        };
        //current profile for
        const { profile_for_id } = reduceForm(list);
        //prev profile for
        const prevProfileId = reduceForm(prevProps.list).profile_for_id;
        //change in profile_for_id --> gender
        if (profile_for_id && profile_for_id !== prevProfileId) {
            if (male.includes(profile_for_id)) {
                setMale(true);
            } else if (female.includes(profile_for_id)) {
                setMale(false);
            } else {
                setGender({
                    value: null,
                    isDisabled: false
                });
            }
        }
    }

    render() {
        return (
            <FormCon
                clear={[SIGNUP_FORM, SIGNUP]}
                onSubmit={this.handleSubmit}
                className="border container mb-5 mt-3 pb-4 pt-4 relative signup-form-container"
            >
                <h3 className="text-center pb-2">Sign Up for free</h3>
                <div className="row">
                    <List
                        RenderItem={SignupIp}
                        list={this.props.list}
                        formName={this.formName}
                        title="signupform"
                    />
                    <div className="col-12">
                        <div className="d-flex align-items-center mb-3 ml-1">
                            <div className="multiple-checkbox">
                                <input
                                    onChange={e =>
                                        this.setState({
                                            terms: e.target.checked
                                        })
                                    }
                                    checked={this.state.terms}
                                    id="is_number_public-0"
                                    type="checkbox"
                                />

                                <label
                                    htmlFor="is_number_public-0"
                                    className="radio-label d-flex align-items-center"
                                >
                                    I Accept your{" "}
                                </label>
                            </div>
                            <Link
                                className="f-14 text-underline ml-1"
                                to="/terms-and-conditions"
                            >
                                Terms and Conditions
                            </Link>
                        </div>
                    </div>
                    <div className="signup-submit-btn col-md-12 d-flex align-items-center justify-content-center">
                        <LoadingBtn
                            onSubmit={this.handleSubmit}
                            className="btn custom-btn"
                            disabled={
                                this.props.formData.fetching ||
                                !this.state.terms
                            }
                            type="submit"
                            fetching={this.props.formData.fetching}
                        >
                            Sign up
                        </LoadingBtn>
                    </div>
                </div>
            </FormCon>
        );
    }
}

const mapStateToProps = ({ signup_form, signup }) => ({
    list: signup_form,
    formData: signup
});

const mapDispatchToProps = {
    signUpSubmit,
    setFormErr,
    openModal,
    setFormValue
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUp);
