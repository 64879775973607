import React from 'react'

const LabelIp = ({children, label, isOptional}) => (
    <div className="row mb-2">
        <div className="col-md-4">
            <p className="ml-1 f-500">
                {label}
                {!isOptional && <span className="required ml-1">*</span> }
            </p>
        </div> 
        <div className="col-md-8">
            {children}
        </div>
    </div>
)

export default LabelIp
