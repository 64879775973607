import React, { Component } from 'react'
import SubmitBtn from '../common/submitbtn';
import { offers_url } from '../../redux/actions/constants';
import Paybtn from './paybtn';

class ApplyCoupon extends Component {

    state = {
        coupon: "",
        res: null,
        error: false
    };

    handleChange = e => {
        const { value } = e.target;
        this.setState({ coupon: value.toUpperCase(), error: false })
    }

    handleClick = res => {
        this.setState({ res, error: false })
    }

    handleErr = err => {
        this.setState({ error: true, res: null })
    }

    render() {
        const { coupon, error, res } = this.state;
        const { discount_price, original_price, id } = this.props.pack;
        let coupAmt = 0, payAmt = 0, coupon_code = null;
        if (discount_price) {
            payAmt = discount_price;
            if (res) {
                coupon_code = res.id;
                const offerAmt = payAmt * (res.offer / 100);
                coupAmt = offerAmt > res.max_amount ? res.max_amount : offerAmt;
                payAmt = payAmt - coupAmt;
            }
        }
        return (
            <div>
                <div className="container mb-3">
                    <div className="row relative">
                        <div className="col-8 p-0">
                            <input
                                type="text"
                                placeholder="Enter coupon code"
                                value={coupon}
                                className="form-control"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-4 p-0">
                            <SubmitBtn
                                disabled={!coupon}
                                className="btn custom-btn btn-block"
                                config={{
                                    url: offers_url + coupon + "/validate_offer"
                                }}
                                succFunc={res => this.handleClick(res)}
                                errFunc={err => this.handleErr(err)}
                            >
                                Apply
                            </SubmitBtn>
                        </div>
                        {error && <p className="coupon-err f-12 color-red m-0">Invalid Coupon</p>}
                    </div>
                </div>
                <div>
                    <div className="payment-detail">
                        <span >Original Price</span>
                        <span>Rs{original_price}</span>
                    </div>
                    <div className="payment-detail">
                        <span >Offered price</span>
                        <span>Rs{discount_price}</span>
                    </div>
                    <div className="payment-detail">
                        <span >Coupon Amount</span>
                        <span className="logo-blue-color">
                            - Rs{coupAmt}
                        </span>
                    </div>
                    <div className="payment-detail mt-2 bg-light">
                        <span>Payable Amount</span>
                        <span className="color-green">Rs{payAmt}</span>
                    </div>
                    <Paybtn
                        package_id={id}
                        coupon_code={coupon_code}
                        className="btn custom-btn btn-block pay-btn mt-3"
                    >
                        Pay
                    </Paybtn>
                </div>
            </div>
        )
    }
}

export default ApplyCoupon
