import React from 'react'
import Logo from './logo';
import NavLinks from './navlinks';
import HeaderProfile from './headerprofile';
import Hambtn from './hambtn';
import SiginForm from "./signinform";

const Header = ({ isLoggedIn = false, isSignIn = true, profilePic, notiCount = 0 }) => (
    <header className="header">
        <div
            className={`header-con pl-4 pr-4 ${isLoggedIn ? `header-height` : `header-land-height`}`}
        >
            {isLoggedIn && <Hambtn />}
            <Logo isLoggedIn={isLoggedIn} />
            {isLoggedIn && <NavLinks notiCount={notiCount} isLoggedIn={isLoggedIn} />}
            {
                isLoggedIn ?
                    <HeaderProfile profile_pic={profilePic} /> :
                        isSignIn ? <SiginForm /> : null
            }
        </div>
    </header>
)

export default Header
