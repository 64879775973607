import React from 'react'
import { ModalCon, CloseModalWrapper, CloseModalBtn } from '../../containers/Modal/closemodal';
import Center from '../center';
import PhoneView from '../../containers/Modal/phoneview';
import { activities_url } from '../../redux/actions/constants';
// import cross from "../../assets/img/cross-out.png"

//modal name PHONE_MODAL
const PhoneModal = ({id, profile_pic, user, age, district, uid, user_details, religion_details, ...props}) => (
    <CloseModalWrapper className="modal-wrapper">
        <ModalCon className="phone-modal-container slideInDown">
            <Center className="w-100">
                <div className="phonemodal-pic">
                    <img src={profile_pic} alt={user.first_name} />
                </div>
            </Center>
            <div className="phonemodal-details">
                <div className="container-fluid text-center pt-75">
                    <h5>{user.first_name} {user.last_name}</h5>
                    {district && <p className="mb-0">Lives in <span className="logo-blue-color">{district.name}</span></p>}
                    {uid && <p className="mb-0 logo-red-color f-600">{uid}</p>}
                    <p className="mb-0 f-600">
                        {age && `${age} Yrs | `}
                        {user_details && user_details.height && `${user_details.height.name} | `}
                        {religion_details && religion_details.religion && `${religion_details.religion.name}`}
                    </p>
                    <PhoneView url={`${activities_url}${id}/number_view/`} />
                    <CloseModalBtn className="btn btn-link pt-2 pb-2 logo-red-color f-12 text-underline">
                        close
                    </CloseModalBtn>
                </div>
            </div>
        </ModalCon>
    </CloseModalWrapper>
)

export default PhoneModal
