import { formReducer } from "./formreducer";
import { 
    SIGNUP_FORM, 
    PARTNER_BASIC_INFO, PARTNER_PHY, PARTNER_ASTRO, PARTNER_EDU, PARTNER_HABITS, OTP, QUICK_SEARCH_FORM, ADV_BASIC, ADV_EDU, ADV_ASTRO, ADV_HABITS, ADV_FILTER, PROFILE_SETTING, CHANGE_PASSWORD, EDITUSER_BASIC_INFO, EDITUSER_PHY, EDITUSER_ASTRO, EDITUSER_EDU, EDITUSER_FAMILY, EDITUSER_HABITS, EDITUSER_DESCRIPTION, FORGOT_FORM, RESET_FORM, CONTACT_FORM, DEACTIVE_FORM 
} from "../actions/constants";
import signupinputs from "../../utils/Forms/signupinputs";
import { quickSearch } from "../../utils/Forms/quicksearchinputs";
import { basicSearch, eduSearch, astroSearch, habitSearch, filters } from "../../utils/Forms/advsearchinputs";
import { profSet, changePassword, resetPassword, contactForm, deacSet } from "../../utils/Forms/manageforms";

//signupform
export const signup_form = formReducer(SIGNUP_FORM, signupinputs);

//register/edit profile
export const edituser_basic = formReducer(EDITUSER_BASIC_INFO);
export const edituser_phy = formReducer(EDITUSER_PHY);
export const edituser_astro = formReducer(EDITUSER_ASTRO);
export const edituser_edu = formReducer(EDITUSER_EDU);
export const edituser_family = formReducer(EDITUSER_FAMILY);
export const edituser_habits = formReducer(EDITUSER_HABITS);
export const edituser_desc = formReducer(EDITUSER_DESCRIPTION);

//otp page
export const otp_input = formReducer(OTP, [
    {
        inputProps: {
            type: "number",
            name: "otp",
            value: '',
            placeholder: "Enter otp",
            maxLength: 6
        }
    }
]);

//forgot page
export const forgot_input = formReducer(FORGOT_FORM, [
    {
        inputProps: {
            type: "number",
            name: "mobile_number",
            value: '',
            placeholder: "Enter your mobile number"
        }
    }
])

//Reset pwd
export const resetpwd_input = formReducer(RESET_FORM, resetPassword)

//partner page
export const partner_basic = formReducer(PARTNER_BASIC_INFO);
export const partner_phy = formReducer(PARTNER_PHY);
export const partner_astro = formReducer(PARTNER_ASTRO);
export const partner_edu = formReducer(PARTNER_EDU);
export const partner_habits = formReducer(PARTNER_HABITS);
export const quick_search_form = formReducer(QUICK_SEARCH_FORM, quickSearch);
export const basic_advsearch = formReducer(ADV_BASIC, basicSearch);
export const edu_advsearch = formReducer(ADV_EDU, eduSearch);
export const astro_advsearch = formReducer(ADV_ASTRO, astroSearch);
export const habit_advsearch = formReducer(ADV_HABITS, habitSearch);
export const filter_advsearch = formReducer(ADV_FILTER, filters);

//manage
export const privacy_setting = formReducer(PROFILE_SETTING, profSet);
export const change_pwd = formReducer(CHANGE_PASSWORD, changePassword);
export const contact_form = formReducer(CONTACT_FORM, contactForm);
export const deact_form = formReducer(DEACTIVE_FORM, deacSet);