import React, { Component } from 'react'
import List from '../list';

class Accordion extends Component { 

    state = {
        active: 1
    };

    render() {
        return (
            <List 
                RenderItem={AccordionItem}
                list={this.props.list}
                title="accordion"
                active={this.state.active}
                onClick={(id) => this.setState({ active: id })}
            />
        )
    }
}

class AccordionItem extends Component {

    constructor(props) {
        super(props);
        this.panel = React.createRef();
    }

    componentDidMount() {
        this.acc();
    }

    componentDidUpdate(prevProps) {
        if(this.props.active !== prevProps.active) {
            this.acc();
        }
    }

    acc = () => {
        const { active, id } = this.props;
        const cur = this.panel.current;
        const { scrollHeight } = cur;
        if (active === id) {
            cur.style.maxHeight = scrollHeight + "px";
        } else {
            cur.style.maxHeight = null;
        }
    }

    render() {
        const { active, id, onClick, question, answer } = this.props;
        return (
            <React.Fragment>
                <button
                    onClick={() => onClick(id)}
                    className={`accordion${active === id ? " acc-active" : ""}`}
                >
                    {question}
                </button>
                <div ref={this.panel} className="acc-panel border-left border-right border-bottom">
                    <div className="mt-2 mb-3">{answer}</div>
                </div>
            </React.Fragment>
        )
    }
}

export default Accordion
