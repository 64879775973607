import React, { Fragment } from 'react'
import Section from '../section';
import PageHeading from './pageheading';
import Contactus from '../../containers/Manage/contactus';
import Accordion from './accordion';
import {Link} from 'react-router-dom'

const Help = () => {
    return (
        <div>
            <PageHeading heading="Help" />
            <Section className="pt-4 pb-4">
                <div className="col-lg-8 mb-5">
                    <h5 className="mb-3">Frequently asked questions</h5>
                    <Accordion 
                        list={[
                            {
                                id: 1,
                                question: "1. Steps to Register",
                                answer: (
                                    <ul>
                                        <li>Log on to <Link className="logo-red-color" to="/">www.tharagar.com</Link></li>
                                        <li>Enter the Registered mobile no, E-mail ID and Password.</li>
                                        <li>Click on the <b>SIGN UP</b> option on the homepage.</li>
                                    </ul>
                                )
                            },
                            {
                                id: 2,
                                question: "2. Steps to complete profile / Modification",
                                answer: `Read carefully the following topics will help you to include Basic Information, photos, partner preference, etc. to make your profile complete.
                                Once you are logged in to your account, Select Edit Profile from the menu and make changes to your profile.`
                            },
                            {
                                id: 3,
                                question: `3. Steps to Search Profiles`,
                                answer: (
                                    <Fragment>
                                        <p className="mb-1">Login to your profile with your registered Mobile No and Password. Please select the "Search" option from the top menu bar.</p>
                                        <b>A. Search by Member ID:</b>
                                        <p className="mb-1 p-2">Enter the Matrimony ID of the member whose profile you would like to see.</p>
                                        <b>B. Quick Search:</b>
                                        <p className="mb-1 p-2">Quick search enables search of profiles through Age, Mother Tongue, Marital Status, Religion, caste, Country, Show Profile and Don't show Profiles.</p>
                                        <b>C. Advanced Search:</b>
                                        <p className="mb-1 p-2">This is a detailed search and you will get good results. Options on Height, Physically Challenged, Marital Status, Religion, caste, Sub Caste, Education & occupation, Astrological, Habits, and Filters.</p>
                                    </Fragment>
                                )
                            },
                            {
                                id: 4,
                                question: `4. Steps to Shortlist / Rejected Profiles:`,
                                answer: (
                                    <Fragment>
                                        <b>Shortlist Profiles:</b>
                                        <p className="mb-1 p-2">Select the "Short-list" option <br></br>The profile will be added to your list of short-listed profiles.</p>
                                        <b>Rejected  Profiles:</b>
                                        <p className="mb-1 p-2">Select the "Rejected" option <br></br>The profile will be added to your list of Rejected profiles.</p>
                                    </Fragment>
                                )
                            },
                            {
                                id: 5,
                                question: `5. Requesting for Photo / Mobile No:`,
                                answer: (
                                    <Fragment>
                                        <b>Photo Request:</b>
                                        <p className="mb-1">If a Member activates the photo privacy option, you can't able to view photo. Click on the "Request Photo" option notification goes to the member. After accepting the request, you can see the photo.</p>
                                        <b>Number Request:</b>
                                        <p className="mb-1">An only paid member can view the phone number easily.<br></br>Make a payment and Upgrade your profile as a paid member.<br></br>If a Member activates the Number privacy option, you can't able to view Number directly.<br></br> Click on the "Request Number" option notification goes to the member. After accepting the request, you can see the Number.</p>
                                    </Fragment>
                                )
                            },
                            {
                                id: 6,
                                question: `6. Steps to contact`,
                                answer: (
                                    <Fragment>
                                        <p className="mb-1">Click the "Send Message" option and select the message you wish to send.<br></br>You will be notified once the member replies to your message.</p>
                                        <b>A. By Chat:</b>
                                        <p className="mb-1">You can chat with other members online only if you are a paid member. </p>
                                        <b>B. Phone Contact: </b>
                                        <p className="mb-1">Only paid members can view the verified mobile numbers and call them.</p>
                                    </Fragment>
                                )
                            },
                            {
                                id: 7,
                                question: `7. What are the Packages we have?`,
                                answer: (
                                    <Fragment>
                                        <p className="mb-1">We have three different types of packages ranging from Rs.3000 to Rs.6000. Types of packages & validity</p>
                                        <b>Tharagar Basic: </b>
                                        <p className="mb-1">45 days validity - Rs.3000 (unlimited messages along with 25 phone numbers) </p>
                                        <b>Tharagar Elite: </b>
                                        <p className="mb-1">90 days validity - Rs.3950 ( unlimited messages along with 100 phone numbers)</p>
                                        <b>Tharagar Premium: </b>
                                        <p className="mb-1">180 days validity - Rs.6000 (unlimited messages along with 150 phone numbers)</p>
                                    </Fragment>
                                )
                            },
                            {
                                id: 8,
                                question: `8. Steps to  Upgrade / Membership Plan`,
                                answer: (
                                    <ul>
                                        <li>Select the Packages what we have.</li>
                                        <li>Make a payment and upgrade your profile to a paid member.</li>
                                        <li>You can make online payments through credit card/debit card/net banking, pay at banks, Tharagar Matrimony offices, Payment Associates and Easy Payment facilities.</li>
                                    </ul>
                                )
                            },
                            {
                                id: 9,
                                question: `9. How to Deactivate my profile:`,
                                answer: (
                                    <ul>
                                        <li>Login to your account. In the My Home page, click on the manage link.</li>
                                        <li>List of options shows in the manage page, click on the Deactivate link to deactivate your profile.</li>
                                        <li>After the deactivation process, your profile will be hidden from our members and you will not be able to contact any member until you activate your profile.</li>
                                    </ul>
                                )
                            },
                        ]}
                    />
                </div>
                <div className="col-md-4">
                    <div className="sticky-dash-nav mb-3">
                        <Contactus />
                    </div>
                </div>
            </Section>
        </div>  
    )
}

export default Help
