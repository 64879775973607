import React from 'react'
import Section from '../section';
import PageHeading from './pageheading';

const Terms = () => {
    return (
        <div>
            <PageHeading heading="Terms & Conditions" />
            <Section className="pt-4">
                <div className="col-md-12">
                    <p>Terms and Conditions of Tharagar.com</p>
                    <p>This website and App is operated by TMORE TECHNOLOGIES PRIVATE LIMITED. Throughout the site and App, the terms “we”, “us” and “our” refer to Tmore technologies private limited. Tmore technology offers this website and App, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                    <p>By visiting our site/App and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”). These Terms of Service apply  to all users of the site/App, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                    <p>Please read these Terms of Service carefully before accessing or using our website/App. By accessing or using any part of the site/App, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website/App or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                    <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website/App. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website/App following the posting of any changes constitutes acceptance of those changes.</p>
                    <p>To get started with our Tharagar.com team you need to register as a member and must accept these terms and conditions. In acceptance of Tharagar.com terms and conditions you are providing access to make your profile visible to the other members of the site/App. On your visit or signing up with Tharagar.com, you consciously accept the terms and conditions as set out herein below</p>
                    <p>This Site/App Service is a serious matchmaking platform to seek a life partner and is not a casual dating site/App. Before registration be clear with the terms and policies.</p>
                    <p>Password is like a ToothBrush DO NOT SHARE it with anyone. You are responsible for maintaining the confidentiality of your login credentials you designate during the registration process, and you are solely responsible for all activities that occur under your account. You agree to immediately notify us of any disclosure or unauthorized use of your account or any other breach of security, and ensure that you log out from your account at the end of each session.</p>
                    <p>To register as a member of Tharagar.com or use this Site/App, you must be legally competent and of legal marriageable age as per the laws of India, As of  now, 18 years or over for females and 21 years or over for males.</p>
                    <p>Tharagar.com shall act on the basis of the information that may be submitted by you, believing the same to be true and accurate even if the information is provided during the registration by your family, friends, relatives on your behalf under your express consent. Tharagar.com is under no obligation to verify the accuracy or genuineness of the information submitted by you.</p>
                    <p>To report fraud or misuse, write to us giving full details of your case.</p>
                    <p>If at any time Tharagar.com, in its sole discretion, is of the opinion or has any reason to believe that You are not eligible to become a member or that you have made any misrepresentation about your eligibility, Tharagar.com hereby reserves the right to forthwith terminate your membership and/or your right to use the services of Tharagar.com without any refund of any monies paid to Tharagar.com</p>
                    <p>If at any point of time Tharagar.com comes to know or has reasons to believe that any information provided by you for registration or otherwise is found to be untrue, inaccurate, or incomplete then Tharagar.com shall have full right to suspend or terminate your membership and forfeit the amount paid by you towards membership fee and refuse to service you thereafter. Your account will be blocked permanantly.</p>
                    <p>You hereby expressly solicit and invite Tharagar.com and/or its authorized personnel to communicate to you through any telecom resources in the registered number provided by you to explain, explicate and clarify the various services provided by Tharagar.com and to assist, aid or support you in availing the said services of Tharagar.com</p>
                    <p>If at any time, you wish to discontinue receiving any communication (including, but not limited to emails, sms and phone calls) from Tharagar.com, You must inform the same to Tharagar.com and/or its authorized personnel regarding such discontinuance .You hereby agree that, unless expressly communicated to You about discontinuing communication from Tharagar.com or its authorized personnel, it will be deemed to be that you want to continue and solicit and invite all such or other communications from Tharagar.com</p>
                    <p>You represent that you or the mobile number submitted by you is not registered with the Do Not Disturb / National Customer Preference Register and/or you have already changed your registration suitably.</p>
                    <p>Further and in any event, you do hereby also unconditionally agree and undertake that this invitation and solicitation shall supersede any preferences set by you with or registration done with the Do Not Disturb ("DND Register")/ National Customer Preference Register ("NCPR"). Without prejudice to the aforesaid and in any event, by expressly inviting and soliciting the services from Tharagar.com, you also unconditionally agree that your rights under the Telecom Commercial Communications Customer Preference Regulations, 2010 or any subsequent amendments thereto or under NCPR, are kept in abeyance or remain extinguished till you expressly communicate for discontinuation of relationship.</p>
                    <p>You also unconditionally agree to indemnify Tharagar.com and its officers against all losses, damages, penalties, costs or consequences whether direct or indirect, that may arise out of any breach or violation of the aforesaid representation, commitment and undertaking.</p>
                    <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                    <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
                    <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website/App through which the service is provided, without express written permission by us.</p>
                    <p>Prices for our service are subject to change without notice.We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
                    <p>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools. Any use by you of  tools offered through the site/App is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</p>
                    <p>We may also, in the future, offer new services and/or features through the website/App (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</p>
                    <p>If there is any error, We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information</p>
                    <p>When you visit Tharagar.com or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this site/App. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                    <p>Questions about the Terms of Service should be sent to us at <a href="email:support@tharagar.com"  target="_blank" rel="noopener noreferrer">support@tharagar.com</a></p>
                </div>
            </Section>
        </div>  
    )
}

export default Terms
