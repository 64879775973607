import React, { Component } from 'react'
import { connect } from 'react-redux'
import List from '../../components/list';
import Center from '../../components/center';
import Formbox from './formbox';
import { setFormErr, setFormValue, updateOptions, addForm, removeForm } from '../../redux/actions/formactions';
import { REGISTER } from '../../redux/actions/constants';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { reduceForm, validate, urlParams, scrollToEL } from '../../utils/Forms/validation';
import { registerSubmit } from '../../redux/actions/user';
import FormCon from '../Form/formcon';
import { edituserform, descProps } from '../../utils/Forms/editdetails';
import InitEdit from './initedit';
import { Redirect } from "react-router-dom";
import { openModal } from '../../redux/actions/common';


export class EditProfileForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            list: [],
            showdesc: true
        };
    }

    setFormList = () => {
        let list = edituserform;
        let showdesc = true;
        if(!this.props.register && this.props.location) {
            const search = this.props.location.search;
            if(search){
                const {edit} = urlParams(search);
                if(edit === descProps.slug){
                    list = [];
                    showdesc = true;
                } else {
                    let editlist = list.filter(item => item.slug === edit);
                    if(editlist.length > 0){
                        list = editlist;
                        showdesc = false;
                    }
                }
            }
        }
        this.setState({list, showdesc});
    }

    componentDidMount(){
        this.setFormList();
    }

    componentDidUpdate(prevProps) {
        const { register, location } = this.props;
        //form list
        if(!register && location){
            if(location.search !== prevProps.location.search){
                this.setFormList();
            }
        }
    }

    generateDesc = () => {
        this.props.openModal("AUTO_DESC_MODAL");
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { setFormErr, registerSubmit } = this.props;
        let isErr = false, elName = null;
        this.state.list.forEach(x => {
            const setErr = formName => payload => setFormErr(formName, payload);
            const isvaliderr = validate(this.props[x.inputsName], setErr(x.formName));
            if (isvaliderr) {
                if(!elName) elName = isvaliderr;
                isErr = true;
            }
        });
        if (isErr) {
            if(elName) {
                scrollToEL(elName);
            }
            return;
        }
        let postData = {};
        [...edituserform, descProps].forEach(x => {
            const { inputsName } = x;
            const datalist = this.props[inputsName];
            postData[inputsName] = reduceForm(datalist);
        })
        const { country_id, state_id, district_id, city_id, date_of_birth, caste_id, sub_caste_id, number_of_children, gothram, marital_status_id, mother_tongue_id, ...restBasic } = postData.edituser_basic;
        const { dosham_id, dosham_choices, ...restastro } = postData.edituser_astro;
        const { about_me } = postData.edituser_desc;
        const { annual_income, ...restEdu } = postData.edituser_edu;
        let regData = {
            country_id, state_id, district_id, date_of_birth,
            user_details: {
                ...restBasic,
                marital_status_id,
                ...postData.edituser_phy,
                ...postData.edituser_family,
                ...postData.edituser_habits
            },
            religion_details: {
                ...restastro,
                caste_id
            },
            education_details: restEdu,
            about_me,
            mother_tongue_id
        };
        if (number_of_children && marital_status_id > 1) {
            regData.user_details.number_of_children = number_of_children;
        }
        regData.religion_details.dosham_choices = parseInt(dosham_choices, 10);
        if (dosham_id && parseInt(dosham_choices, 10)) {
            regData.religion_details.dosham_id = dosham_id;
        }
        if (city_id) {
            regData.city_id = city_id;
        }
        regData.religion_details.gothram = gothram || "";
        if (annual_income) {
            regData.education_details.annual_income = annual_income;
        }
        if(sub_caste_id) {
            regData.religion_details.sub_caste_id = sub_caste_id;
        }
        registerSubmit(regData);
    }

    render() {
        const { formData, register } = this.props;
        if (formData.data.id && !register) {
            return <Redirect to="/my-profile" />
        }
        return (
            <InitEdit>
                <FormCon
                    clear={[REGISTER]}
                    onSubmit={this.handleSubmit}
                >
                    <List
                        RenderItem={Formbox}
                        list={this.state.list}
                        title="edituserForm"
                    />
                    {
                        this.state.showdesc &&
                        <Formbox 
                            {...descProps}
                            fullWidth
                        >
                            <div className="pt-2 pb-3">
                                <div
                                    onClick={this.generateDesc} 
                                    className="btn btn-link f-14 float-right"
                                >
                                    Auto generate
                                </div>
                            </div>
                        </Formbox>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <Center>
                                <LoadingBtn onSubmit={this.handleSubmit} className="btn custom-btn" disabled={this.props.formData.fetching} type="submit" fetching={this.props.formData.fetching}>
                                    Submit
                                </LoadingBtn>
                            </Center>
                        </div>
                    </div>
                </FormCon>
            </InitEdit>
        )
    }
}

const mapStateToProps = ({
edituser_basic,
edituser_phy,
edituser_astro,
edituser_edu,
edituser_family,
edituser_habits,
edituser_desc,
user,
register }) => ({
    edituser_basic,
    edituser_phy,
    edituser_astro,
    edituser_edu,
    edituser_family,
    edituser_habits,
    edituser_desc, user, formData: register
})

const mapDispatchToProps = {
    setFormValue, updateOptions, setFormErr, registerSubmit, addForm, removeForm, openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm)
