import React from 'react'
import SearchById from '../../containers/Search/searchbyid';
import Heading from '../../containers/Dashboard/heading';
import RouteTabs from '../Routes/routetabs';
import { NavLink } from "react-router-dom"
import QuickSearch from './quicksearchform';
import AdvSearch from './advsearchform';
import SearchResultsPage from './searchresults';

const SearchPage = (props) => (
    <div>
        <Heading />
        <div className="container pt-3 pb-5">
            <div className="row">
                <div className="col-lg-12 mt-1">
                    <div className="row align-items-end">
                        <div className="col-md-4">
                            <SearchById {...props} />
                        </div>
                        <div className="col-md-8">
                            <NavLink
                                to="/search"
                                className="btn dash-filterbtn theme-color mr-2 mb-3"
                                exact
                                activeClassName="dash-filterbtn-active"
                            >
                                Quick Search
                            </NavLink>
                            <NavLink
                                to="/search/advanced"
                                className="btn dash-filterbtn theme-color mr-2  mb-3"
                                exact
                                activeClassName="dash-filterbtn-active"
                            >
                                Advanced Search
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <RouteTabs
                title="manage"
                tabs={
                    [
                        {
                            path: "/",
                            exact: true,
                            component: QuickSearch
                        },
                        {
                            path: "/advanced",
                            component: AdvSearch
                        },
                        {
                            path: "/results",
                            component: SearchResultsPage
                        }
                    ]
                }
            />
        </div>
    </div>
)

export default SearchPage
