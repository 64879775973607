import React from 'react'
import { Link } from "react-router-dom"
import Logo from './logo';

export default function Footer1() {
    return (
        <footer className="footer-bottom-section">
            <div className="container">
                <div className="footer-bottom">
                    <div className="footer-center mt-3">
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <div>
                                    <Logo />
                                    <p>Tharagar.com is No.1 Tamil Matrimonial Site in Tamilnadu</p>
                                </div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <h6>CONTACT US</h6>
                                <ul className="footer-list">
                                    <li>
                                        <a href="mailto:support@tharagar.com">support@tharagar.com</a>
                                    </li>
                                    <li>
                                        <a href="tel:7373333674">73733 33674</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <h6>HELP & SUPPORT</h6>
                                <FooterList />
                            </div>
                            <div className="mb-3 col-md-3 text-center">
                                <h6>FOLLOW US ON</h6>
                                <ul className="footer-list social-link">
                                    <li>
                                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Tharagar">
                                            <i className="fab fa-facebook-square"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/tharagar_com">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a rel="noopener noreferrer" target="_blank" href="http://twitter.com/Tharagar_com">
                                            <i className="fab fa-twitter-square"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <hr />
                            <p className="footer-help">
                                Copyrights <i className="far fa-copyright mr-1 f-12"></i>2019 <span className="logo-red-color">Tharagar.com</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}


const FooterList = () => (
    <ul className="footer-list">
        <li>
            <Link to="/safety-and-security">
                Safety & Security
            </Link>
        </li>
        <li>
            <Link to="/privacy-policy">
                Privacy Policy
            </Link>
        </li>
        <li>
            <Link to="/terms-and-conditions">
                Terms & conditions
            </Link>
        </li>
        <li>
            <Link to="/help">
                Help
            </Link>
        </li>
    </ul>
)