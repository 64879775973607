import React from 'react'
import ProfileBox, { ProfileContent } from './profilebox';

const ANY = "Any";

const handleNull = key => key || ANY;

const PartnerPrefers = ({ marital_status, age_from, age_to, income_from, income_to, mother_tongue, citizenship, caste, sub_caste, height_from, height_to, religion, education, star, dosham, employed_in, occupation, smoking_habits, drinking_habits, food_habits, dosham_choices, state, district }) => {
    return (
        <ProfileBox edit={"/manage/partner-preferences"} title="Partner Preferences">
            <ProfileContent
                title="Age"
                detailKey={age_from && age_to ? `${age_from} yrs - ${age_to} yrs` : ANY}
            />
            {height_from && height_to && <ProfileContent
                title="Height"
                detailKey={height_from && height_to ? `${height_from} cms - ${height_to} cms` : ANY}
            />}
            <ProfileContent
                title="Language"
                detailKey={handleNull(mother_tongue)}
            />
            <ProfileContent
                title="Citizenship"
                detailKey={handleNull(citizenship)}
            />
            <ProfileContent
                title="State"
                detailKey={handleNull(state)}
            />
            <ProfileContent
                title="District"
                detailKey={handleNull(district)}
            />
             <ProfileContent
                title="Marital Status"
                detailKey={handleNull(marital_status)}
            />
            <ProfileContent
                title="Religion"
                detailKey={handleNull(religion)}
            />
            <ProfileContent
                title="Caste"
                detailKey={handleNull(caste)}
            />
            <ProfileContent
                title="Sub Caste"
                detailKey={handleNull(sub_caste)}
            />
            <ProfileContent
                title="Star"
                detailKey={handleNull(star)}
            />
            <ProfileContent
                title="Dosham"
                detailKey={dosham_choices ? dosham_choices === "Yes" ? dosham : dosham_choices : ANY}
            />
            <ProfileContent
                title="Education"
                detailKey={handleNull(education)}
            />
            <ProfileContent
                title="Employment"
                detailKey={handleNull(employed_in)}
            />
            <ProfileContent
                title="Occupation"
                detailKey={handleNull(occupation)}
            />
            <ProfileContent
                title="Annual Income"
                detailKey={!isNaN(income_from) && !isNaN(income_to) ? `Rs ${income_from !== 0 ? income_from / 100000 : 0} Lakhs to ${(income_to !== 0 ? income_to / 100000 : 0).toLocaleString("en-IN")} Lakhs` : ANY}
            />
            <ProfileContent
                title="Food Habit"
                detailKey={handleNull(food_habits)}
            />
            <ProfileContent
                title="Smoking Habit"
                detailKey={handleNull(smoking_habits)}
            />
            <ProfileContent
                title="Drinking Habit"
                detailKey={handleNull(drinking_habits)}
            />
        </ProfileBox>
    )
}

export default PartnerPrefers
