import { partnervalues } from "./mastervalues";

const isMulti = true;
const isOptional = true;

//partner preferences
export const quickSearch = [
    {
        label: "Age",
        inputProps: {
            type: "range",
            name: "age",
            unit: "yrs",
            mulitple: 1,
            isOptional,
            maxValue: 70,
            minValue: 18,
            value: {
                min: 18,
                max: 40,
            }
        }
    },
    {
        label: "Mother Toungue",
        inputProps: { 
            type: "select",
            isOptional,
            name: "language",
            value: [],
            placeholder: "Select Languages",
            isMulti,
            options: partnervalues.languages
        }
    },
    {
        label: "Martial Status",
        inputProps: {
            type: "select",
            isOptional,
            name: "marital_status",
            value: [],
            isMulti,
            placeholder: "Marital Status",
            options: partnervalues.marital_status
        }
    },
    {
        label: "Religion",
        inputProps: {
            type: "select",
            isOptional,
            name: "religion",
            value: [],
            placeholder: "Select Religion",
            isMulti,
            options: partnervalues.religions,
            decendant: "caste",
            decendentOptionName: "castes"
        }
    },
    {
        label: "Caste",
        inputProps: {
            type: "select",
            isOptional,
            name: "caste",
            value: [],
            placeholder: "Select caste",
            isMulti,
            options: []
        }
    },
    {
        label: "Country",
        inputProps: {
            type: "select",
            isOptional,
            name: "country",
            placeholder: "Select Country",
            isMulti,
            options: partnervalues.country,
        }
    },
    {
        label: "Show Profile",
        inputProps: {
            type: "radioGroup",
            isOptional,
            value: ['0'],
            name: "show_profile",
            options: [
                {
                    value: '0',
                    label: "All profiles",
                },
                {
                    value: '1',
                    label: "With photo"
                },
            ]
        }
    },
    {
        label: "Don't show Profile",
        inputProps: {
            type: "radioGroup",
            isOptional,
            name: "reject_profile",
            value: [],
            isMulti,
            options: [
                {
                    value: '3',
                    label: "Ignored"
                },
                {
                    value: '1',
                    label: "Contacted"
                },
                {
                    value: '2',
                    label: "Viewed"
                },
                {
                    value: '4',
                    label: "Shorlisted"
                },
            ]
        }
    }
];