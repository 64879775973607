import React from "react";
import Section from "../section";
import Recentlyjoined from "../../containers/Landing/recentlyjoined";

const RecentMembers = () => (
    <Section className="landing-section-pad">
        <div className="col-12 pb-4">
            <h1 className="text-center mb-1">Brides and Grooms</h1>
            <p className="text-center mb-1">Find your life partner</p>
            <div className="divider div-transparent div-dot"></div>
        </div>
        <div className="col-12 pt-2">
            <div className="row">
                <Recentlyjoined />
            </div>
        </div>
    </Section>
);

export default RecentMembers;
