import React from 'react'
import ProfileBox, { ProfileContent } from './profilebox';

const ProfileDetails = ({ userData, edit }) => {
    const { user, gender, age, mother_tongue, district, state, country, user_details, religion_details, education_details, city, about_me, uid, profile_for, is_number_verified, is_email_verified } = userData;
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <ProfileBox edit={edit ? "/manage/edit-profile?edit=basic-information" : false} title={
                        <span>
                            Basic Information
                            <span className="ml-2 f-14">({uid})</span>
                        </span>
                    }>
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="p-0 m-0 list-unstyled">
                                    <ProfileContent
                                        title="Name"
                                        detailKey={`${user.first_name} ${user.last_name}`}
                                    />
                                    <ProfileContent
                                        title="Profile created by"
                                        detailKey={
                                            [2, 3].includes(profile_for.id) ? 
                                            `Parents`:
                                                [4, 5].includes(profile_for.id) ?
                                                    `Siblings` : profile_for.name
                                        }
                                    />
                                    {edit && 
                                        <ProfileContent
                                            title="Phone number"
                                            detailKey={user.username}
                                            verified={is_number_verified}
                                        />
                                    }
                                    {edit && 
                                        <ProfileContent
                                            title="Email Id"
                                            detailKey={user.email}
                                            verified={is_email_verified}
                                        />
                                    }
                                    { !edit && <ProfileContent
                                        title="Gender"
                                        detailKey={gender}
                                    /> }
                                    <ProfileContent
                                        title="Age"
                                        detailKey={`${age} Yrs`}
                                    />
                                    <ProfileContent
                                        title="Mother tongue"
                                        detailKey={mother_tongue.name}
                                    />
                                    <ProfileContent
                                        title="Country"
                                        detailKey={country.name}
                                    />
                                    <ProfileContent
                                        title="State"
                                        detailKey={state.name}
                                    />
                                    {district && <ProfileContent
                                        title="District"
                                        detailKey={district.name}
                                    />}
                                    {city && <ProfileContent
                                        title="City"
                                        detailKey={city.name}
                                    />}
                                    {religion_details.religion &&
                                        <ProfileContent
                                            title="Religion"
                                            detailKey={religion_details.religion.name}
                                        />
                                    }
                                    {religion_details.caste &&
                                        <ProfileContent
                                            title="Caste"
                                            detailKey={religion_details.caste.name}
                                        />
                                    }
                                    {religion_details.sub_caste &&
                                        <ProfileContent
                                            title="Sub Caste"
                                            detailKey={religion_details.sub_caste.name}
                                        />
                                    }
                                    {religion_details.gothram &&
                                        <ProfileContent
                                            title="Gothram"
                                            detailKey={religion_details.gothram}
                                        />
                                    }
                                    {user_details &&
                                        <ProfileContent
                                            title="Marital Status"
                                            detailKey={user_details.marital_status.name}
                                        />
                                    }
                                    {user_details && user_details.number_of_children > 0 &&
                                        <ProfileContent
                                            title="Number of children"
                                            detailKey={`${user_details.number_of_children}`}
                                        />
                                    }
                                </ul>
                            </div>
                        </div>
                    </ProfileBox>
                </div>
                {
                    about_me && 
                    <div className="col-md-12">
                        <ProfileBox 
                            edit={edit ? "/manage/edit-profile?edit=about-me" : false} 
                            title="About me"
                        >
                            <div className="row">
                                <div className="col-md-12 f-14">
                                    {about_me}
                                </div>
                            </div>
                        </ProfileBox>
                    </div>
                }
                {
                    user_details && 
                    <React.Fragment>
                    <div className="col-md-12">
                        <ProfileBox edit={edit ? "/manage/edit-profile?edit=physical-characteristics" : false} title="Physical Info">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="p-0 m-0 list-unstyled">
                                        <ProfileContent
                                            title="Height"
                                            detailKey={user_details.height.name}
                                        />
                                        <ProfileContent
                                            title="Weight"
                                            detailKey={`${user_details.weight} kgs`}
                                        />
                                        <ProfileContent
                                            title="Body type"
                                            detailKey={user_details.body_type.name}
                                        />
                                        <ProfileContent
                                            title="Skin Tone"
                                            detailKey={user_details.skin_tone.name}
                                        />
                                        <ProfileContent
                                            title="Physical Status"
                                            detailKey={`${user_details.is_physically_challenged ? 'Physically Disabled' : "Normal"}`}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </ProfileBox>
                    </div>
                    <div className="col-md-12">
                        <ProfileBox edit={edit ? "/manage/edit-profile?edit=family-profile" : false} title="Family Details">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="p-0 m-0 list-unstyled">
                                    <ProfileContent
                                        title="Family Status"
                                        detailKey={user_details.family_status}
                                    />
                                    <ProfileContent
                                        title="Family Type"
                                        detailKey={user_details.family_type}
                                    />
                                    <ProfileContent
                                        title="Family Values"
                                        detailKey={user_details.family_values}
                                    />
                                    {user_details.no_of_brother !== 0 &&
                                    <ProfileContent
                                        title="Number of Brothers"
                                        detailKey={user_details.no_of_brother}
                                    />}
                                    {user_details.no_of_brother_married !== 0 &&
                                    <ProfileContent
                                        title="Number of Brothers Married"
                                        detailKey={user_details.no_of_brother_married}
                                    />}
                                    {user_details.no_of_sister !== 0 &&
                                    <ProfileContent
                                        title="Number of Sisters"
                                        detailKey={user_details.no_of_sister}
                                    />}
                                    {user_details.no_of_sister_married !== 0 &&
                                    <ProfileContent
                                        title="Number of Sisters Married"
                                        detailKey={user_details.no_of_sister_married}
                                    />}
                                </ul>
                            </div>
                        </div>
                        </ProfileBox>
                    </div>
                    </React.Fragment>
                }
                {
                    religion_details &&
                    <div className="col-md-12">
                        <ProfileBox 
                            edit={edit ? "/manage/edit-profile?edit=astrological-information" : false}
                            title="Astrological Details"
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="p-0 m-0 list-unstyled">
                                        {religion_details.star &&
                                            <ProfileContent
                                                title="Star"
                                                detailKey={religion_details.star.name}
                                            />
                                        }
                                        {religion_details.raasi &&
                                            <ProfileContent

                                                title="Raasi"
                                                detailKey={religion_details.raasi.name}
                                            />
                                        }
                                        {religion_details &&
                                            <ProfileContent
                                                title="Dosham"
                                                detailKey={Array.isArray(religion_details.dosham) && religion_details.dosham.length > 0 ? religion_details.dosham : "No" }
                                            />
                                        }
                                    </ul>
                                </div>
                            </div>
                        </ProfileBox>
                    </div>
                }
                {
                    education_details && 
                    <div className="col-md-12">
                        <ProfileBox 
                        edit={edit ? "/manage/edit-profile?edit=education-and-occupation" : false} 
                        title="Education & Occupation"
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="p-0 m-0 list-unstyled">
                                    {education_details && education_details.highest_education && <ProfileContent
                                        title="Highest Education"
                                        detailKey={education_details.highest_education.name}
                                    />}
                                    {education_details && education_details.employed_in && <ProfileContent
                                        title="Employment Type"
                                        detailKey={education_details.employed_in.name}
                                    />}
                                    {education_details && education_details.occupation && <ProfileContent
                                        title="Occupation"
                                        detailKey={education_details.occupation.name}
                                    />}
                                    {education_details && education_details.annual_income !== 0 && <ProfileContent
                                        title="Annual Income"
                                        detailKey={`Rs ${education_details.annual_income / 100000} Lakhs`}
                                    />}
                                </ul>
                            </div>
                        </div>
                    </ProfileBox>
                    </div>
                }  
                {   user_details && 
                    <div className="col-md-12">
                        <ProfileBox 
                            edit={edit ? "/manage/edit-profile?edit=habits" : false} 
                            title="Habits"
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="p-0 m-0 list-unstyled">
                                        <ProfileContent
                                            title="Food Habit"
                                            detailKey={user_details.food_habits}
                                        />
                                        <ProfileContent
                                            title="Smoking Habit"
                                            detailKey={user_details.smoking_habits}
                                        />
                                        <ProfileContent
                                            title="Drinking Habit"
                                            detailKey={user_details.drinking_habits}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </ProfileBox>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProfileDetails
