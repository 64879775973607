import React  from 'react'
import { connect } from 'react-redux'

export const Heading = ({user}) => (
    <div className="pt-4 pb-2 border-bottom gradientbg">
         <div className="container">
            <h5 className="text-center">Welcome! {user.user.first_name}. You have landed on the right place as your partner.</h5>
        </div>
    </div>
)

const mapStateToProps = ({user}) => ({
    user
})

export default connect(mapStateToProps)(Heading)
