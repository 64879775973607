import React from 'react'
import Section from '../section';
import PageHeading from './pageheading';

const PrivacyPolicy = () => {
    return (
        <div>
            <PageHeading heading="Privacy Policy" />
            <Section className="pt-4">
                <div className="col-md-12">
                    <p>Once you accept terms & conditions, you are granting permission to access your details that you provide on-site/App. The information collected from members who apply for various service in our site/App are of two kind (public and private). Tharagar.com allows members to submit public and private information on behalf of others - child/ward, sibling or friend. If such child/ward, sibling or friend does not wish this information to be displayed, she/he has the option to request removal of such information after providing the necessary evidence(Government Proof) that the information belongs to her/him.</p>
                    <p>You have options to  hide your personal information like Phone number and Email Id. Tharagar.com does not sell, rent or loan your personal information’s to any individuals unless as required by the law.</p>
                    <p>The general detail of the members and visitors who wish to join includes the name, gender, material status, age, citizenship, address, Zip code, E-mail id, photograph and phone number, etc., The information gathered are only to acknowledge that whether we are able to complete your needs.  These details provided in public area hides the personal things like phone number and email address, as in public view the details are displayed to your partner. </p>
                    <p>You may terminate your membership at any time, for any reason by writing to Tharagar.com. In the event you terminate your membership, you will not be entitled to a refund of any unutilized subscription fees. Refund process is not available in Tharagar.com</p>
                    <p>Tharagar.com has the right to change its features and services without any notice from time to time based on member comments or as a result of a change of Company policy. However, changes will be updated in the Privacy Policy page.If you do not agree for use of your information, please do not use or access this website/App.</p>
                    <p>We use a secure server for credit card transactions to protect the credit card information of our users and Cookies are used to store the login information. Cookies are small files placed on your hard drive that will assist us in providing our services. You may also encounter Cookies or identical/related devices on certain pages of the website/App that are placed by third parties. We do not control the use ,of cookies by third parties.</p>
                    <p>Where required or permitted by law, information may be provided to others, such as regulators and law enforcement agencies or to protect the rights ,property or personal safety of other members or the general public . We may voluntarily share your information with law enforcement agencies / Gateway service providers / anti-fraud solution provider(s) if we feel that the transaction is of suspicious nature.</p>
                    <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.</p>
                    <p>Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. For more insight, you may also want to read terms and conditions of razorpay on <a href="https://razorpay.com" target="_blank" rel="noopener noreferrer">https://razorpay.com</a></p>
                    <p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</p>
                    <p>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s)in which that service provider or its facilities are located.</p>
                    <p>Once you leave our website/App or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our Terms of Service.</p>
                    <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. We use cookies to maintain session of your user. It is not used to personally identify you on other websites/Apps.</p>
                    <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. If our service is acquired or merged with another company, your information may be transferred to the new owners so that we may continue our service to you.</p>
                    <p>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Officer at <a href="email:support@tharagar.com"  target="_blank" rel="noopener noreferrer">support@tharagar.com</a></p>
                    <br></br>
                </div>
            </Section>
        </div>  
    )
}

export default PrivacyPolicy
