import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { pwdValid } from '../../utils/Forms/validation';
import { loginSubmit } from '../../redux/actions/user';
import { NumberOnlyInput } from '../../components/Form/numberip';
import { Link } from "react-router-dom"
import { closeModal } from '../../redux/actions/common';

class SignInForm extends Component {

    state = {
        login_phone: '',
        login_pwd: '',
        login_phone_err: '',
        login_pwd_err: '',
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            [name + "_err"]: false
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        const { login_phone, login_pwd } = this.state;
        // if (!phoneValid(login_phone)) {
        if (!login_phone) {
            this.setState({ login_phone_err: true });
            return;
        }
        if (!pwdValid(login_pwd)) {
            this.setState({ login_pwd_err: true });
            return;
        }
        this.props.loginSubmit({
            username: login_phone,
            password: login_pwd,
            client: Math.random() * 100000
        })
    }

    componentDidUpdate(prevProps){
        const { closeModal, login_form, isModal } = this.props;
        if(isModal) {
            if(login_form.data && login_form.data !== prevProps.login_form.data ) {
                closeModal();
            }
        }
    }

    render() {
        const { login_phone, login_pwd, login_phone_err, login_pwd_err } = this.state;
        return (
            <div className="login-form-con">
                <form noValidate className="login-form" onSubmit={this.handleSubmit}>
                    <div className={`pr-2 ${login_phone_err ? "form-control-err" : ''}`}>
                        <NumberOnlyInput type="tel" value={login_phone} onChange={this.handleChange} name="login_phone" className={"form-control headerip"} placeholder="Phone number" maxLength={12} />
                    </div>
                    <div className={`pr-2 relative ${login_pwd_err ? "form-control-err" : ''}`}>
                        <input value={login_pwd} type="password" onChange={this.handleChange} name="login_pwd" className="form-control headerip" placeholder="Password" />
                    </div>
                    <LoadingBtn 
                        onSubmit={this.handleSubmit} 
                        type="submit" 
                        className="btn custom-btn login-btn" 
                        fetching={this.props.login_form.fetching}>
                        Sign in
                    </LoadingBtn>
                </form>
                <Link to="/forgot-password" className="btn btn-link f-12 forgot-link">
                    Forgot password?
                </Link>
            </div>
        )
    }
}

const mapStateToProps = ({ login_form }) => ({
    login_form
})

const mapDispatchToProps = {
    loginSubmit, closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm)
