import React from "react";

const Section = ({ children, className = "" }) => (
    <section className={className}>
        <div className="container">
            <div className="row">
                {children}
            </div>
        </div>
    </section>
);

export default Section;
