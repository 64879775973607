import React from 'react'
import Quicksearchform from '../../containers/Search/quicksearchform';
import Recomended from './recommed';

const QuickSearch = () => {
    return (
        <div className="row mt-4">
            <Quicksearchform />
            <Recomended limit={3} />
        </div>
    )
}

export default QuickSearch
