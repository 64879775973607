import React from 'react'
import Heading from '../../containers/Dashboard/heading';
import Packages from '../../containers/upgrade/packages';
import Currentplan from '../../containers/upgrade/currentplan';

const UpgradePage = () => {
    return (
        <div>
            <Heading />
            <Currentplan />
            <Packages />
        </div>
    )
}

export default UpgradePage
