import React from 'react'
// import ProgressLoader from './toploader';
import Section from '../section';

const RegisterLayout = ({children, heading}) => (
    <div>
        {/* <ProgressLoader width={25} /> */}
        <div className="greybg text-center p-4 mb-4">
            <h3 className="font-weight-normal">{heading}</h3>
        </div>
        <Section>
            <div className="col-md-12 nopadding mb-5">
                {children}
            </div>  
        </Section>
    </div>
)

export default RegisterLayout
