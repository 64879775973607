import React from 'react'
import { connect } from 'react-redux'
import List from '../../components/list';
import UploadPhoto from '../../components/Register/uploadphoto';
import {Link} from "react-router-dom"

//creates [1,2,4, ...n]
export const createNarray = (n) => Array.apply(null, {length: n}).map(Function.call, Number);

//list of user photos
export const Photoslist  = ({photos}) => {
    const list = photos || [];
    const addlist = createNarray(6 - list.length );
    return (
        <div className="row pb-2">
            <List 
                list={list}
                RenderItem={UploadPhoto}
                title="user-photos"
            />
            <List 
                list={addlist}
                RenderItem={UploadPhoto}
                title="add-user-photos"
            />
        </div>
    )
}


const PhotoContinue = ({photos}) => {
    return (
        <Link className="btn btn-outline-success" to="/partner-preferences">
            {Array.isArray(photos) && photos.length > 0 ? "Continue" : "Skip & continue" }
        </Link>
    )
}

const mapStateToProps = ({user}) => ({
    photos: user.photos
})

const mapDispatchToProps = {
    
}


export const PhotoCon = connect(mapStateToProps, mapDispatchToProps)(PhotoContinue);

export default connect(mapStateToProps, mapDispatchToProps)(Photoslist)
