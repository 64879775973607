import React, { Component } from 'react';
import {Provider} from "react-redux"
import store from './redux';
import Page from './components/page';
import SocketHandle from './containers/SocketHandle';

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<SocketHandle />
				<Page />
			</Provider>
		);
	}
}

export default App;
