import React from 'react'

const MatchBtn = ({children, fetching=false, ...restProps}) => {
    return (
        <button 
            disabled={fetching}
            className={`btn match-func-btn`}
            {...restProps}
        >
            {children}
        </button>
    )
}

export default MatchBtn
