import mastervalues from "../../getmaster";

const { religions, languages, country, relationship, height, body_types, careers, degrees, employment, stars, dosham, skin_tone, marital_status, issues_type } = mastervalues;

export const getcCastes = id => religions.filter(x => x.id === id)[0].castes;

const gender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
];

export const familyvalues = [
    { value: 'Orthodox', label: 'Orthodox' },
    { value: 'Traditional', label: 'Traditional' },
    { value: 'Moderate', label: 'Moderate' },
    { value: 'Liberal', label: 'Liberal' },
];

export const familtypes = [
    { value: 'Joint Family', label: 'Joint Family' },
    { value: 'Nuclear Family', label: 'Nuclear Family' },
    { value: 'Others', label: 'Others' },
];

export const familstatus = [
    { value: 'Middle Class', label: 'Middle Class' },
    { value: 'Upper Middle Class', label: 'Upper Middle Class' },
    { value: 'Rich', label: 'Rich' },
    { value: 'Affluent', label: 'Affluent' },
];

export const eating = [
    { value: 'Vegetarian', label: 'Vegetarian' },
    { value: 'Non-Vegetarian', label: 'Non-Vegetarian' },
    { value: 'Eggetarian', label: 'Eggetarian' }
];

export const drinking = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'Occasionally', label: 'Occasionally' }
];

const doesnt = { value: `Doesn't matter`, label: `Doesn't matter` };

const dial_codes = country.reduce((acc, cur) => {
    return [...acc, { value: cur.id, label: cur.dial_code }]
}, []);


export const signupvalues = {
    religions: religions,
    languages: languages,
    relationship, gender, dial_codes,
    country
};

export const registervalues = {
    country, height, body_types, careers, degrees, employment, stars, dosham, skin_tone, marital_status,
    familyvalues, familtypes, familstatus, eating, drinking
};

export const profilevalues = {
    ...registervalues, ...signupvalues
};

export const partnervalues = {
    ...profilevalues,
    eating: [doesnt, ...eating],
    drinking: [doesnt, ...drinking]
};

export const issues = issues_type;