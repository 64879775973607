import React from 'react'
import CustomBtn from '../../components/Buttons/CustomBtn';
import FetchData from '../../hocs/fetchdata';

const PhoneView = ({ url }) => {
    return (
        <FetchData 
            url={url}
            WrappedComp={({ data, fetching, error  }) => (
                <div className="pt-2 pb-2 bg-light border mt-2">
                    <div>
                        <h6 className="mb-1">Phone Number</h6>
                        <div className="f-16">
                            {
                                fetching ? 
                                "Fetching phone number..." : 
                                data ? 
                                    data.username  ?
                                        <a href={`tel:${data.username}`}>{data.username}</a> : 
                                        "Number request has been sent"
                                    : 
                                error ? (
                                    <div>
                                        <div className="f-14">{error}</div>
                                        <CustomBtn to="/upgrade" className="mt-2">Upgrade</CustomBtn>
                                    </div>
                                )
                                : null
                            }
                        </div>
                    </div>
                </div>
            )}
        />
    )
}

export default PhoneView
