import thunk from "redux-thunk"
import localStorageMiddleware from "./localStorageMiddleware";
import apiInstance from "../apiInstace";
import SocketMiddleware from "./socketmiddleware";

const api = apiInstance;

const middlewares = [
    thunk.withExtraArgument(api), 
    SocketMiddleware,
    localStorageMiddleware
];

export default middlewares

