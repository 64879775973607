import React from "react"
import ContentLoader from "react-content-loader"


const Left = props => (
    <div className="col-md-3">
        <ContentLoader
            height={500}
            width={330}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="20.23" y="19.67" rx="0" ry="0" width="295.55" height="232.2" />
            <circle cx="42.65" cy="281.62" r="17.95" />
            <circle cx="115.65" cy="281.62" r="17.95" />
            <circle cx="195.65" cy="279.62" r="17.95" />
            <circle cx="280.65" cy="279.62" r="17.95" />
            <rect x="31.41" y="328.67" rx="0" ry="0" width="67" height="9.01" />
            <rect x="30.41" y="349.67" rx="0" ry="0" width="261" height="7" />
            <rect x="31.41" y="373.67" rx="0" ry="0" width="1" height="0" />
            <rect x="28.41" y="373.67" rx="0" ry="0" width="206" height="9" />
            <rect x="62.41" y="404.67" rx="0" ry="0" width="0" height="0" />
            <rect x="29.41" y="399.67" rx="0" ry="0" width="85" height="74.8" />
            <rect x="130.23" y="397.67" rx="0" ry="0" width="90.16" height="77.28" />
            <rect x="239.41" y="396.67" rx="0" ry="0" width="80.23" height="79.17" />
        </ContentLoader>
    </div>
)

const Middle = props => (
    <div className="col-md-6">
        <div className="pb-3">
            <ContentLoader
                height={160}
                width={630}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                {...props}
            >
                <rect x="24.98" y="19.67" rx="0" ry="0" width="510.65" height="21" />
                <rect x="27.41" y="61.67" rx="0" ry="0" width="432.35" height="8" />
                <rect x="27.41" y="89.67" rx="0" ry="0" width="249.44" height="9" />
                <rect x="28.41" y="121.67" rx="0" ry="0" width="370.27" height="9" />
            </ContentLoader>
        </div>
        <div className="pb-3">
            <ContentLoader
                height={160}
                width={640}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                {...props}
            >
                <rect x="24.98" y="19.67" rx="0" ry="0" width="510.65" height="21" />
                <rect x="27.41" y="61.67" rx="0" ry="0" width="432.35" height="8" />
                <rect x="27.41" y="89.67" rx="0" ry="0" width="249.44" height="9" />
                <rect x="28.41" y="121.67" rx="0" ry="0" width="370.27" height="9" />
            </ContentLoader>
        </div>
    </div>
)

export const DetailLoader = () => (
    <div className="container-fluid pt-4 pb-5">
        <div className="row">
            <Left></Left>
            <Middle />
            <Left></Left>
        </div>
    </div>
)