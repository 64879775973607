import { Component } from 'react'
import { sendSocketMsg } from '../redux/actions/chat';
import { connect } from 'react-redux'

export default connect(state => ({ user: state.user || {} }), { sendSocketMsg })(class SocketHandle extends Component {

    componentDidMount() {
        if(this.props.user.id) {
            this.joinRoom(this.props.user.id);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.user.id && this.props.user.id !== prevProps.user.id) {
            if(prevProps.user.id) {
                this.leaveRoom(prevProps.user.id);
            }
            this.joinRoom(this.props.user.id);
        }
    }

    joinRoom = id => {
        this.props.sendSocketMsg("join room", {
            room: `userRoom-${id}`
        });
    }

    leaveRoom = id => {
        this.props.sendSocketMsg("leave room", {
            room: `userRoom-${id}`
        });
    }

    render() {
        return null
    }

    componentWillUnmount(){
        this.leaveRoom(this.props.user.id);
    }

})
