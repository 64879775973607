const empty = {
    languages: [],
    employment: [],
    relationship: [],
    marital_status: [],
    height: [],
    body_types: [],
    skin_tone: [],
    dosham: [],
    degrees: [],
    careers: [],
    stars: [],
    religions: [],
    country: [],
    issues_type: []
};

const mastervalues = window.master_values || empty;

export default mastervalues;
