import { signupvalues } from "./mastervalues";
import { EDITUSER_ASTRO } from "../../redux/actions/constants";

export const depends = {
    martial: (setData, val) => setData({
        name: "number_of_children",
        data: { type: val > 1 ? 'number' : 'hidden' }
    }),
    relSubCaste: (setData, val) => {
        let data = { isOptional: val && val !== 1 };
        setData({
            name: "sub_caste_id",
            data
        });
        setData({
            name: "gothram",
            data
        });
        setData(
            {
                name: "star_id",
                data
            },
            EDITUSER_ASTRO
        );
        setData(
            {
                name: "raasi_id",
                data
            },
            EDITUSER_ASTRO
        );
        setData(
            {
                name: "dosham_choices",
                data
            },
            EDITUSER_ASTRO
        );
    },
    caste: (setData, val) => setData({
        name: "gothram",
        data: { isOptional: val && val !== 5 }
    }),
    employ: (setData, val) => {
        setData({
            name: "occupation_id",
            data: { type: val === 15 ? "hidden" : "select" }
        });
        setData({
            name: "annual_income",
            data: { type: val === 15 ? "hidden" : "select" }
        });
    },
    income: (setData, val) => setData({
        name: "annual_income",
        data: { isOptional: val === 60 }
    }),
    dosham: (setData, val) => setData({
        name: "dosham_id",
        data: { type: parseInt(val, 10) ? "radioGroup" : "hidden" }
    }),
    bothers: (setData, val) => setData({
        name: "no_of_brother_married",
        data: {
            type: (val && parseInt(val, 10) > 0) ? "number" : "hidden",
            max: val && !isNaN(parseInt(val, 10)) ? parseInt(val, 10) : 0
        }
    }),
    sisters: (setData, val) => setData({
        name: "no_of_sister_married",
        data: {
            type: (val && parseInt(val, 10) > 0) ? "number" : "hidden",
            max: val && !isNaN(parseInt(val, 10)) ? parseInt(val, 10) : 0
        }
    }),
    profileFor: (setData, val) => {
        let label = null;
        let isDisabled = true;
        if ([2, 4].includes(val)) {
            label = "Male";
            isDisabled = true;
        }
        if ([3, 5].includes(val)) {
            label = "Female";
            isDisabled = true;
        }
        setData({
            name: "gender",
            data: {
                value: {
                    label,
                    value: label
                },
                isDisabled
            }
        })
    },
    phValid: (setData, val) => {
        const { min_number, max_number } = signupvalues.country.filter(x => x.id === val)[0];
        if (min_number && max_number) {
            setData({
                name: "username",
                data: {
                    minLength: min_number,
                    maxLength: max_number
                }
            })
        }
    },
    reason: (setData, val) => setData({
        name: "other_reason",
        data: { type: val === "Others" ? "select" : "hidden" }
    }),
};