import React from "react";
import logo from "../../assets/img/logosvg.svg"
import { Link } from "react-router-dom"

const Logo = ({isLoggedIn}) => (
    <Link to={isLoggedIn ? "/dashboard" : "/"}>
        <img className="logo" src={logo} alt="" />
    </Link>
);

export default Logo;