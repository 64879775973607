import React from 'react'
import { connect } from 'react-redux'
import MatchBtn from '../../components/Buttons/matchbtn';
import rejecticon from "../../assets/img/func/thumb-down.png";
import rejectactive from "../../assets/img/func/thumb-active.png";
import { addToast } from '../../redux/actions/common';
import { activities_url, REJECT_MATCH, _FULFILLED, _REJECTED } from '../../redux/actions/constants';
import Submit from '../common/submit';

const RejectBtn = ({ is_ignore, id, dispatch, activeID, baseId }) => (
    <Submit
        config={{
            url: `${activities_url}${id}/rejected_profile/`
        }}
        succFunc={(payload) => {
            dispatch({
                type: REJECT_MATCH + _FULFILLED,
                payload,
                baseId,
                id: activeID
            });
            dispatch(addToast(`Profile ${payload.is_ignore ? "added to" : "removed from"} Rejected Profiles`, !payload.is_ignore));
        }}
        errFunc={(payload) => {
            dispatch({
                type: REJECT_MATCH + _REJECTED,
                payload,
                id: activeID
            })
        }}
        Btn={({ fetching, onClick }) => (
            <MatchBtn
                fetching={fetching}
                onClick={onClick}
            >
                <img src={is_ignore ? rejectactive : rejecticon} alt="" />
                <p>Reject profile</p>
            </MatchBtn>
        )}
    />
)

export default connect(null)(RejectBtn)