import React, { Component } from 'react';
import Portal from '../../components/common/portal';
import { connect } from 'react-redux';
import { closeModal } from "../../redux/actions/common"
import CropModal from '../../components/Modals/cropmodal';
import PhoneModal from '../../components/Modals/phonemodal';
import PageLoaderModal from './pageloader';
import ConfirmModal from '../../components/Modals/confirmodal';
import SideModal from '../../components/Modals/sidemodal';
import DeactiveModal from '../../components/Modals/deactive';
import AutoDescModal from '../../components/Modals/AutoDesc';
import SignInModal from '../../components/Modals/loginmodal';
import PromoteModal from '../../components/Modals/PromoteModal';
import LoaderModal from './LoaderModal';
import ErrModal from '../../components/Modals/ErrModal';

class ModalContainer extends Component {

    selectModal(){
        switch(this.props.modalName){
            case "CROP_IMAGE_MODAL":
                return CropModal
            case "PHONE_MODAL":
                return PhoneModal
            case "PAGE_LOADER":
                return PageLoaderModal
            case "CONFIRM_MODAL":
                return ConfirmModal
            case "SIDE_MODAL":
                return SideModal
            case "DEACT_MODAL":
                return DeactiveModal
            case "AUTO_DESC_MODAL":
                return AutoDescModal
            case "SIGNIN_MODAL":
                return SignInModal
            case "PROMOTE_MODAL":
                return PromoteModal
            case "LOADER_MODAL":
                return LoaderModal
            case "ERROR_MODAL":
                return ErrModal
            default:
                return null
        }
    }

    render() {
        const Modalbody = this.selectModal();
        return (
            this.props.open ?
            <Portal>
            {
                Modalbody &&
                <Modalbody {...this.props.modalProps} />
            }
            </Portal> : null
        )
    }
}

const Modal = connect((state) => state.modalReducer, {closeModal} )(ModalContainer);

export default Modal;