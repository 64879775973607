import React from 'react'
import { Link, NavLink } from "react-router-dom"

const NavLinks = ({ isLoggedIn = false, isSide = false, notiCount = 0, showUpgrade = false }) => (
    <div className={`d-flex justify-content-evenly ${isSide ? `flex-column` : `header-links align-items-center`}`}>
        {
            isLoggedIn &&
            <React.Fragment>
                <PageLink
                    to={"/dashboard"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Dashboard</span>
                </PageLink>
                <PageLink
                    to={"/search"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Search</span>
                </PageLink>
                <PageLink
                    to={"/manage"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Manage</span>
                </PageLink>
                <PageLink
                    to={"/help"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Help</span>
                </PageLink>
                <PageLink
                    target="_blank"
                    rel="noopener noreferrer"
                    to={"/chat"}
                    isLoggedIn={isLoggedIn}
                ><span className="header-span">Chat</span></PageLink>
                <PageLink
                    to={"/notifications"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Notifications</span>
                    {notiCount !== 0 && <div className="noti-badge">{notiCount}</div>}
                </PageLink>
                {
                    showUpgrade &&
                    <PageLink
                        to={"/upgrade"}
                        isLoggedIn={isLoggedIn}
                    >
                        <span className="header-span">Upgrade</span>
                    </PageLink>
                }
            </React.Fragment>
        }

    </div>
)

const PageLink = ({ isLoggedIn, children, icon, ...restProps }) => (
    isLoggedIn ?
        <NavLink
            {...restProps}
            className={`d-flex align-items-center nav-link relative`}
            activeClassName="active"
        >
            {icon && <i className={`fas fa-${icon} headericon`}></i>}
            {children}
        </NavLink>
        :
        <Link
            className="d-flex align-items-center nav-link"
            {...restProps}
        >
            {children}
        </Link>
)

export default NavLinks
