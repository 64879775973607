// import { updateUser } from "../actions/user";
// import { LOGOUT, _FULFILLED, LIST_NOTI, _ADD_DATA } from "../actions/constants";
import io from 'socket.io-client';
import { addChatMsg, chatMsgSent } from '../actions/chat';
import { wsURL } from '../apiInstace';
import { updateUser } from '../actions/user';

// const socketUrl = process.env.NODE_ENV === "development" ? "https://tharagarchat.billioncart.com/"

//common socket middlware
const SocketMiddleware = ({ dispatch, getState }) => {
    
    const socket = io(wsURL);

    socket.on('message', (data) => {
        console.log(data);
        dispatch(addChatMsg(data.room, data.msg));
    });

    socket.on('message sent', (data) => {
        dispatch(chatMsgSent(data.room, data.id));
    });

    socket.on('notification count', (notification) => {
        dispatch(updateUser({ notification }));
    });

    return next => action => {
        
        if (action.type === "SEND_WEBSOCKET_MESSAGE" && action.emit) {
            socket.emit(action.emit, action.payload);
        }
                
        return next(action);
    }
}

// const dispatchAction = ({ action_type, result }) => {
//     switch (action_type) {
//         case "notification_count":
//             return updateUser({ notification: result })
//         case "notification_message":
//             return {
//                 type: LIST_NOTI + _ADD_DATA,
//                 payload: result
//             }
//         default:
//             return {
//                 type: "RECIEVE_WEBSOCKET_MESSAGE",
//                 result
//             }
//     }
// }

export default SocketMiddleware