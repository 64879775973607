import React from "react";
import Section from "../section";
import googleplaydark from "../../assets/img/google-play-dark.png";
import applesoon from "../../assets/img/app-apple-coming-soon.png";
import cardio from "../../assets/img/cardiogram.png";
import Center from "../center";
import BannerSection from "./BannerSection";
import RecentMembers from "./RecentMembers";
import Info from "./Info";

const LandingPage = () => (
    <React.Fragment>
        <BannerSection />
        <RecentMembers />
        <div className="info-banner" />
        <Info />
        <Section className="pt-5 pb-5 download-bg">
            <div className="col-12">
                <h1 className="mb-4 text-center">Download the App</h1>
                <Center className="flex-wrap">
                    {/* <a href="https://play.google.com/store" className="d-inline-block m-2"> */}
                    <img
                        src={googleplaydark}
                        style={{ maxWidth: `250px` }}
                        alt=""
                    />
                    {/* </a> */}
                    <span className="d-inline-block m-2">
                        <img
                            src={applesoon}
                            style={{ maxWidth: `250px` }}
                            alt=""
                        />
                    </span>
                </Center>
            </div>
        </Section>
        <Section className="landing-section-pad">
            <div className="col-12">
                <h1 className="font-weight-normal mb-4">About us</h1>
                <div className="row">
                    <div className="col-md-8">
                        <p className="pt-2 text-justify">
                            Tharagar.com is the best matchmaking matrimonial
                            service, to find your life partner for marriage and
                            help parents to search & give better life partner
                            for their son/daughter. We're committed to providing
                            services with extraordinary standards. It's a great
                            privilege to be a part of your search for your life
                            partner. Our mission is to give a satisfying
                            experience to the people by offering a service that
                            is easy and safe. In a short span of time, we
                            created a significant place for our-self as compared
                            to other matrimonial sites.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <Center>
                            <img className="cardio-img" src={cardio} alt="" />
                        </Center>
                    </div>
                </div>
            </div>
        </Section>
    </React.Fragment>
);

export default LandingPage;
