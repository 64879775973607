import React, { Component } from "react";
import { connect } from "react-redux";
import List from "../../components/list";
import Center from "../../components/center";
import Formbox from "../Register/formbox";
import {
    reduceForm,
    filterPostData,
    toQuery
} from "../../utils/Forms/validation";
import { partnerSubmit } from "../../redux/actions/user";
import { advform } from "../../utils/Forms/advsearchinputs";
import CustomBtn from "../../components/Buttons/CustomBtn";
import { ADV_ASTRO } from "../../redux/actions/constants";
import { setFormValue } from "../../redux/actions/formactions";
import { withRouter } from "react-router-dom"

export class AdvForm extends Component {
    handleClick = () => {
        const searchData = filterPostData(
            advform.reduce((acc, cur) => {
                return { ...acc, ...reduceForm(this.props[cur.inputsName]) };
            }, {})
        );
        if (searchData.age) {
            searchData["max_age"] = searchData.age.max;
            searchData["min_age"] = searchData.age.min;
            delete searchData.age;
        }
        if (searchData.weight) {
            searchData["max_weight"] = searchData.weight.max;
            searchData["min_age"] = searchData.weight.min;
            delete searchData.weight;
        }
        if (searchData.height) {
            searchData["max_height"] = searchData.height.max;
            searchData["min_height"] = searchData.height.min;
            delete searchData.height;
        }
        this.props.history.push(`/search/results${toQuery(searchData)}`);
    };

    componentDidUpdate(prevProps) {
        const { dosham_choices } = reduceForm(this.props.astro_advsearch);
        const prev_astro = reduceForm(prevProps.astro_advsearch);
        if (dosham_choices !== prev_astro.dosham_choices) {
            this.props.setFormValue(ADV_ASTRO, {
                name: "dosham_id",
                data: { type: dosham_choices === "Yes" ? "select" : "hidden" }
            });
        }
    }

    render() {
        return (
            <div className="col-lg-8 mb-3">
                <List RenderItem={Formbox} list={advform} title="advsearch" />
                <div>
                    <Center>
                        <CustomBtn onClick={this.handleClick} className="registerbtn">
                            Search
                        </CustomBtn>
                    </Center>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    basic_advsearch,
    edu_advsearch,
    astro_advsearch,
    habit_advsearch,
    filter_advsearch
}) => ({
    basic_advsearch,
    edu_advsearch,
    astro_advsearch,
    habit_advsearch,
    filter_advsearch
});

const mapDispatchToProps = {
    partnerSubmit,
    setFormValue
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvForm));
