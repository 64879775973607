import React from 'react'
import { Link } from "react-router-dom"
import { ProfileCom } from '../../containers/common/profilecom';
import { HeaderDropDown } from '../Manage/managetabs';

const HeaderProfile = ({ profile_pic }) => {
    return (
        <div className="d-flex">
            <div className="d-flex align-items-center">
                <Link className="btn upgradelink" to="/upgrade">
                    Upgrade
                </Link>
            </div>
            <div className="d-flex align-items-center headerprofile h100p">
                <Link to="/my-profile" className="d-flex align-items-center justify-content-end theme-color">
                    <img className="top-profilepic border" src={profile_pic} alt="" />
                    <p className="m-0 f-12 pl-2 completed-txt"><ProfileCom /><br></br>Completed</p>
                </Link>
                <div className="logout-dropdown">
                    <HeaderDropDown />
                </div>
            </div>
        </div>
        
    )
}

export default HeaderProfile
