import React from 'react'
import {NavLink} from "react-router-dom"
import List from '../list';
import ManageShortTabs from '../../containers/common/ManageShortTabs';

const linksList = [
    {
        to: "/dashboard",
        title: "Recommended Matches",
        heading: "Matches",
    },
    {
        to: "/dashboard/new-matches",
        title: "New Matches",
        heading: "Matches"
    },
    {
        to: "/dashboard/all-matches",
        title: "All Matches",
        heading: "Matches"
    },
    {
        to: "/dashboard/profile-viewed",
        heading: "My Activities",
        title: "Profiles viewed by me"
    },
    {
        to: "/dashboard/shortlisted-profiles",
        title: "Shortlisted profiles",
        heading: "My Activities"
    },
    {
        to: "/dashboard/rejected-profiles",
        title: "Rejected profile",
        heading: "My Activities"
    },
    {
        to: "/dashboard/number-viewed",
        title: "Phone numbers viewed by me",
        heading: "My Activities"
    },
    {
        to: "/dashboard/viewed-my-profile",
        title: "Who viewed my profile",
        heading: "Profile Views"
    },
    {
        to: "/dashboard/viewed-my-number",
        title: "Who viewed my number",
        heading: "Profile Views"
    },
    {
        to: "/dashboard/shortlist-my-profile",
        title: "Who shortlisted my profile",
        heading: "Profile Views"
    },
    {
        to: "/dashboard/sent-requests",
        title: "Sent",
        heading: "Requests"
    },
    {
        to: "/dashboard/recieved-requests",
        title: "Recieved",
        heading: "Requests"
    }
];

const manageLinks = [
    {
        heading: "Matches",
        links: linksList.filter(x => x.heading === "Matches")
    },
    {
        heading: "My Activities",
        links: linksList.filter(x => x.heading === "My Activities")
    },
    {
        heading: "Profile Views",
        links: linksList.filter(x => x.heading === "Profile Views")
    },
    {
        heading: "Requests",
        links: linksList.filter(x => x.heading === "Requests")
    }
];

export const ManageBox = ({heading, links}) => (
    <div>
        <div className="manage-heading">
            {heading}
        </div>
        <ul className="manage-list">
            <List 
                RenderItem={ManageTabLink}
                list={links} 
                title="manage-links"
            />
        </ul>
    </div>
)

export const ManageTabLink = ({to, title}) => (
    <li>
        <NavLink className="manage-link" exact activeClassName="manage-link-active" to={to}>
            {title}
        </NavLink>
    </li>
)

const MangeList = () => (
    <List 
        RenderItem={ManageBox}
        list={manageLinks} 
        title="manage-box"
    />
)

const ManageTabs = () => (
    <ManageShortTabs 
        linksList={linksList}
        children={<MangeList />}
    />
)

export default ManageTabs
