import React from 'react'
import { Switch, Route} from "react-router-dom";
import LandingPage from '../Landing/landingpage';
import RegisterPage from '../Register/registerpage';
import DashboardPage from '../Dashboard/dashboardpage';
import ScrollToTop from '../../containers/common/scrolltop';
import ProfilePage from '../Profile/profilepage';
import SearchPage from '../Search/searchpage';
import OtpPage from '../Register/otppage';
import ChangeNoPage from '../Register/changenopage';
import PhotosUploadPage from '../Register/userphotos';
import PartnerPreferPage from '../Register/partnerprefer';
import UserProfilePage from '../Profile/userprofile';
import ManagePage from '../Manage/managepage';
import WhyTharagar from '../Static/whytharagar';
import SafeSecure from '../Static/safesecure';
import PrivacyPolicy from '../Static/privacypolicy';
import Terms from '../Static/terms';
import ChatPage from '../Chat/chatpage';
import HomeRoute from '../../containers/common/homeroute';
import UserRoute from '../../containers/common/userroute';
import SearchResultsPage from '../Search/searchresults';
import Help from '../Static/help';
import Notifications from '../Notifications/notificationspage';
import UpgradePage from '../upgrade/upgradepage';
import ForgotPage from '../Register/forgotpage';
import ResetOtpPage from '../Register/resetotppage';
import ResetPwdPage from '../Register/resetpwdpage';
import EmailVerify from '../Static/EmailVerify';
import SuccessPage from '../Static/SuccessPage';
import FailurePage from '../Static/Failurepage';
import Notfound from '../Static/Notfound';
import UserUnVerified from '../Static/UserUnVerified';

const Routes = () => (
    <ScrollToTop>
        <Switch>
            <HomeRoute path="/" exact  component={LandingPage} />
            <UserRoute path="/otp" component={OtpPage} />
            <UserRoute path="/chat" component={ChatPage} />
            <Route path="/help" component={Help} />
            <UserRoute path="/search" component={SearchPage} />
            <UserRoute path="/manage" component={ManagePage} />
            <UserRoute path="/register" component={RegisterPage} />
            <UserRoute path="/upgrade" component={UpgradePage} />
            <UserRoute path="/dashboard" component={DashboardPage} />
            <UserRoute path="/notifications" component={Notifications} />
            <UserRoute path="/my-profile" component={UserProfilePage} />
            <UserRoute path="/search-results" component={SearchResultsPage} />
            <UserRoute path="/upload-photos" component={PhotosUploadPage} />
            <UserRoute path="/partner-preferences" component={PartnerPreferPage} />
            <Route path="/registration-complete" component={UserUnVerified} />
            <Route path="/change-mobile-number" component={ChangeNoPage} />
            <Route path="/forgot-password" component={ForgotPage} />
            <Route path="/reset-otp-verify" component={ResetOtpPage} />
            <Route path="/reset-password" component={ResetPwdPage} />
            <Route path="/why-tharagar" component={WhyTharagar} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/safety-and-security" component={SafeSecure} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route path="/email-verification" component={EmailVerify} />
            <Route path="/payment-success" component={SuccessPage} />
            <Route path="/payment-failed" component={FailurePage} />
            <UserRoute path="/profile/:id" component={ProfilePage} />
            <Route component={Notfound} />
        </Switch>
    </ScrollToTop>
)

export default Routes
