import React from 'react'
import { NavLink } from "react-router-dom"
import List from '../list';
import { OpenModalBtn } from '../../containers/Modal/closemodal';
import { logout } from '../../redux/actions/user';
import { LOGOUT } from '../../redux/actions/constants';
import ManageShortTabs from '../../containers/common/ManageShortTabs';

const manLinks = [
    {
        to: "/manage",
        title: "Privacy settings"
    },
    {
        to: "/manage/edit-email",
        title: "Edit email & phone number"
    },
    {
        to: "/manage/edit-profile",
        title: "Edit profile"
    },
    {
        to: "/manage/partner-preferences",
        title: "Edit partner preferences"
    },
    {
        to: "/manage/photos",
        title: "Manage photos"
    },
    {
        to: "/manage/points",
        title: "Membership plan"
    },
    {
        to: "/manage/change-password",
        title: "Change password"
    },
    {
        to: "/manage/deactivate-account",
        title: "Deactivate account"
    },
];

const manageLinks = [
    {
        heading: "Account Setting",
        links: manLinks
    }
];

const ManageTabs = () => {
    return (
        <ManageShortTabs linksList={manLinks}>
            {/* <div className="manage-border"> */}
                <List
                    RenderItem={ManageBox}
                    list={manageLinks}
                    title="manage-box"
                />
                <ul className="manage-list">
                    {/* <li>
                        <OpenModalBtn
                            className="btn logout-btn"
                            modalName="DEACT_MODAL"
                        >
                            Deactivate account
                        </OpenModalBtn>
                    </li> */}
                    <li>
                        <LogoutBtn 
                            className="btn logout-btn" 
                        />
                    </li>
                </ul>
            {/* </div> */}
        </ManageShortTabs>
    )
}

export const ManageBox = ({ heading, links }) => (
    <div >
        <div className="manage-heading">
            {heading}
        </div>
        <ul className="manage-list">
            <List
                RenderItem={ManageTabLink}
                list={links}
                title="manage-links"
            />
        </ul>
    </div>
)

export const ManageTabLink = ({ to, title }) => (
    <li>
        <NavLink className="manage-link" exact activeClassName="manage-link-active" to={to}>
            {title}
        </NavLink>
    </li>
)

export const HeaderDropDown = () => (
    <ul className="logout-dropdown-content">
        <List
            RenderItem={ManageTabLink}
            list={[
                {
                    to: "/my-profile",
                    title: "My Profile"
                },
                {
                    to: "/manage/photos",
                    title: "Upload Photos"
                },
                {
                    to: "/manage",
                    title: "Manage Profile"
                },
            ]}
            title="manage-box"
        />
        <li>
            <LogoutBtn 
                className="btn logout-btn" 
            />
        </li>
    </ul>
)

export const LogoutBtn = ({ className }) => (
    <OpenModalBtn
        className={className}
        modalName="CONFIRM_MODAL"
        modalProps={{
            msg: "Are you sure you want to logout?",
            confirmProps: {
                reducerName: "log_out",
                ntAction: () => logout(),
                formName: LOGOUT
            }
        }}
    >
        Logout
    </OpenModalBtn>
)

export default ManageTabs
