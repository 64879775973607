import React from 'react'
import LoadDataHoc from '../../hocs/loaddatahoc';
// import Center from '../../components/center';
import { MATCH_DETAIL } from '../../redux/actions/constants';
import ProfileDetails from '../../components/Profile/profiledetails';
import Gallery from '../../components/Profile/gallery';
import { RequestMobile, RequestPhoto } from './sendrequest';
import Tscore from '../../components/Profile/tscore';
import { DetailLoader } from '../../components/Profile/detailloader';
import ProfileImage from '../../components/Profile/ProfileImage';
import MatchFunc from '../Dashboard/matchfunc';
import { SimilarMatch } from '../Dashboard/matches';
import profileSvg from "../../assets/img/profile.svg"

export const Profile = ({ apiData }) => {
    if (apiData.fetching) {
        return <DetailLoader />
    }
    if(apiData.error) {
        return (
            <div className="text-center pt-5 pb-5">
                <img className="mb-4" style={{ width: `100%`, maxWidth: `450px` }} src={profileSvg} alt="" />
                <h5 className="mb-5 logo-red-color">PROFILE IS CURRENTLY NOT AVAILABLE</h5>
            </div>
        )
    }
    if (apiData.data.id) {
        const { id, photos, is_number_public, is_photo_public, score, profile_pic } = apiData.data;
        return (
            <div className="container-fluid profile-page pt-3 pb-3">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="sticky-dash-nav">
                            <ProfileImage photos={photos} profile_pic={profile_pic}>
                                <div className="border mt-3 br-5">
                                    <MatchFunc {...apiData.data} />
                                </div>
                            </ProfileImage>
                            <div className="row">
                                {!is_number_public && <RequestMobile postData={id} />}
                                {!is_photo_public && <RequestPhoto hasPhotos={photos && photos.length > 0} postData={id} />}
                            </div>
                            {photos && is_photo_public && Array.isArray(photos) && photos.length > 0 &&
                                <Gallery checkUserPhotos={true} images={photos} />
                            }
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ProfileDetails userData={apiData.data} />
                    </div>
                    <div className="col-lg-3">
                        <div className="border mb-3">
                            <div className="p-1 gradientbg">
                                <div className="text-center pt-2 pb-2">
                                    <h6>T-Score</h6>
                                    <h2 className="mb-0">{score.point}/100</h2>
                                    <p className="f-14 m-0">Match score against your preferences</p>
                                </div>
                            </div>
                            <div className="partner-preferences p-2">
                                <div>
                                    {Tscore(score.matches || [])}
                                </div>
                            </div>
                        </div>
                        <h6>Similar Profiles</h6>
                        <SimilarMatch 
                            url={`users/profile/${id}/similar_profile`}
                            // noNext={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default LoadDataHoc(Profile, MATCH_DETAIL, 'matchDetail');
