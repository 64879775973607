import React from 'react'
import { connect } from 'react-redux'
import MatchBtn from '../../components/Buttons/matchbtn';
import shortlisticon from "../../assets/img/func/heart.png";
import shortlistactive from "../../assets/img/func/heart-active.png";
import { addToast } from '../../redux/actions/common';
import Submit from '../common/submit';
import { activities_url, SHORT_LIST_MATCH, _FULFILLED, _REJECTED } from '../../redux/actions/constants';

const ShortListBtn = ({ is_shortlist, id, dispatch, activeID, baseId }) => (
    <Submit
        config={{
            url: `${activities_url}${id}/shortlist_profile/`
        }}
        succFunc={(payload) => {
            dispatch({
                type: SHORT_LIST_MATCH + _FULFILLED,
                payload,
                baseId,
                id: activeID
            });
            dispatch(addToast(`Profile ${payload.is_shortlist ? "added to" : "removed from"}  Shortlist Profiles`, payload.is_shortlist));
        }}
        errFunc={(payload) => {
            dispatch({
                type: SHORT_LIST_MATCH + _REJECTED,
                payload,
                id: activeID
            })
        }}
        Btn={({ fetching, onClick }) => (
            <MatchBtn
                fetching={fetching}
                onClick={onClick}
            >
                <img src={is_shortlist ? shortlistactive : shortlisticon} alt="" />
                <p>Shortlist profile</p>
            </MatchBtn>
        )}
    />
)

export default connect(null)(ShortListBtn)
