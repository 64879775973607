import React from 'react'
import { connect } from 'react-redux'

const Profilecom = ({user}) => (<span>{user.profile_completed ? user.profile_completed : 0}%</span>)

const mapStateToProps = ({user}) => ({
    user
})

export const ProfileCom = connect(mapStateToProps)(Profilecom);

 
