import React from 'react'
import Select from 'react-select';

const mapSelect = data =>  data.reduce((acc, cur) => {
    return [...acc, {value: cur.value || cur.id || '', label: cur.label || cur.name}]
}, [])

const mapGroup = (data, name) => data.reduce((acc, cur) => {
    return [...acc, {
        label: cur.label || cur.name || '',
        options: mapSelect(cur[name])
    }]
}, []);

const mapData = (options, optgroup) => {
    if(optgroup){
        return mapGroup(options, optgroup);
    }
    return mapSelect(options);
}

const SelectIp = ({options=[], optgroup=false, defaultOptions=[], ...ipProps}) => (
    <Select 
        {...ipProps}
        options={mapData([...defaultOptions, ...options], optgroup)}
        classNamePrefix="react-select" 
    />
)

export default SelectIp
