import React, { memo } from "react";
import { Link } from "react-router-dom";
import MatchFunc from "../../containers/Dashboard/matchfunc";
import ContentLoader from "react-content-loader";
import dateFormat from "../../utils/dateformat";

const getText = type => {
    switch (type) {
        case 5:
            return "Chat interest";
        case 6:
            return "Phone number request";
        case 7:
            return "Photo request";
        case 8:
            return "Photo upload request";
        default:
            return "";
    }
};

const Matches = memo(props => {
    const {
        fetching,
        profile_pic,
        user,
        id,
        user_details,
        religion_details,
        district,
        uid,
        age,
        hideFunc,
        is_online,
        last_seen,
        showRequestText,
        type,
        is_premium
    } = props;
    if (fetching) {
        return <MatchLoader />;
    }
    let reqText = "";
    if (showRequestText) {
        let shoTxt = getText(type);
        if (shoTxt) {
            reqText = `You ${showRequestText} a ${shoTxt}`;
        }
    }
    return (
        <div className="match relative">
            {is_premium && (
                <div className="premium-label">
                    <i className="fas fa-crown"></i>Premium
                </div>
            )}
            <MatchLink
                isUserActive={user.is_active}
                hideFunc={hideFunc}
                className="d-flex p-2 relative theme-color"
                to={`/profile/${id}`}
            >
                <div className="match-image-con">
                    <img
                        className={"match-image"}
                        src={profile_pic}
                        alt={`${user.first_name} ${user.last_name}`}
                    />
                </div>
                <div className="match-details pl-2">
                    <h6 className="text-cap profile-name mt-1 mb-1">{`${user.first_name} ${user.last_name}`}</h6>

                    {district && (
                        <p className="mb-0 f-14">
                            Lives in{" "}
                            <span className="logo-blue-color">
                                {district.name}
                            </span>
                        </p>
                    )}
                    {uid && (
                        <p className="mb-0 f-14 logo-red-color f-600">{uid}</p>
                    )}
                    <p className="mb-1 f-13 f-600 text-break-word">
                        {age && `${age} Yrs | `}
                        {user_details &&
                            user_details.height &&
                            `${user_details.height.name} | `}
                        {religion_details &&
                            religion_details.religion &&
                            `${religion_details.religion.name}`}
                    </p>
                    {!showRequestText && (
                        <React.Fragment>
                            {is_online ? (
                                <div className="d-flex align-items-center color-green f-13 f-600">
                                    <span className="is-online-dot online mr-2" />
                                    Online
                                </div>
                            ) : last_seen ? (
                                <div className="f-12 text-muted">
                                    Last Seen{" "}
                                    {dateFormat(last_seen, {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric"
                                    })}
                                </div>
                            ) : null}
                        </React.Fragment>
                    )}
                    {reqText && (
                        <div className="color-green f-12 f-600">{reqText}</div>
                    )}
                </div>
            </MatchLink>
            {!hideFunc && <MatchFunc {...props} />}
        </div>
    );
});

export const MatchLink = ({
    isUserActive,
    hideFunc,
    children,
    to,
    ...restProps
}) => {
    if (!hideFunc && to && isUserActive !== false) {
        return (
            <Link {...restProps} to={to} target="_blank">
                {children}
            </Link>
        );
    }
    return <div {...restProps}>{children}</div>;
};

export const MatchLoader = ({ size = 6 }) => (
    <div className="match">
        <ContentLoader
            height={150}
            width={340}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="146.42" y="18" rx="4" ry="4" width="117" height="6.4" />
            <rect x="148" y="35" rx="3" ry="3" width="85" height="6.4" />
            <rect x="11" y="8.67" rx="0" ry="0" width="108" height="91.08" />
            <circle cx="37.29" cy="126.96" r="15.79" />
            <circle cx="112.29" cy="127.96" r="15.79" />
            <circle cx="187.29" cy="126.96" r="15.79" />
            <circle cx="277.29" cy="126.96" r="15.79" />
            <rect x="148" y="54.67" rx="0" ry="0" width="134" height="7" />
            <rect x="148" y="73.67" rx="0" ry="0" width="183" height="8.96" />
        </ContentLoader>
    </div>
);

export default Matches;
