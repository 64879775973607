import { partnervalues } from "./mastervalues";
import { PARTNER_BASIC_INFO, PARTNER_PHY, PARTNER_EDU, PARTNER_ASTRO, PARTNER_HABITS } from "../../redux/actions/constants";

const isMulti = true;
const isOptional = true;

const doshamChoices = [
    {
        value: 'Yes',
        label: "Yes",
    },
    {
        value: 'No',
        label: "No"
    },
    { 
        value: `Doesn't matter`, 
        label: `Doesn't matter` 
    }
];

const defaultOptions = [{id: '', name: `Any`}];

//init values
export const init = {
    "gender": null,
    "marital_status": [],
    "mother_tongue": [],
    "age_from": 21,
    "age_to": 30,
    "height_from": 150,
    "height_to": 180,
    "income_from": 100000,
    "income_to": 1000000,
    "religion": [],
    "citizenship": [],
    "state": [],
    "district": [],
    "caste": [],
    "sub_caste": [],
    "education": [],
    "employed_in": [],
    "occupation": [],
    "star": [],
    // "raasi": [],
    "dosham": [],
    "food_habits": "Doesn't matter",
    "smoking_habits": "Doesn't matter",
    "drinking_habits": "Doesn't matter",
    "dosham_choices": "No"
};

//partner preferences
export const basicPrefrences = init => [
    {
        label: "Marital Status",
        inputProps: {
            type: "select",
            name: "marital_status_id",
            value: init.marital_status,
            placeholder: "Any Marital status",
            isMulti,
            isOptional,
            defaultOptions,
            options: partnervalues.marital_status
            
        }
    },
    {
        label: "Age",
        inputProps: {
            isOptional,
            type: "range",
            name: "age",
            unit: "yrs",
            mulitple: 1,
            maxValue: 70,
            minValue: init.gender === "Male" ? 18 : 21,
            value: {
                min: init.age_from,
                max: init.age_to,
            }
        }
    },
    {
        label: "Citizenship",
        inputProps: {
            type: "select",
            name: 'citizenship_id',
            value: init.citizenship,
            placeholder: "Country of Residence",
            options: partnervalues.country,
            decendant: "state_id",
            decendentOptionName: "states",
            isMulti,
            isOptional,
            defaultOptions
        }
    },
    {
        label: "State",
        inputProps: {
            isOptional,
            type: "select",
            name: "state_id",
            value: init.state || [],
            placeholder: "State of Residence",
            options: [],
            decendant: "district_id",
            decendentOptionName: "district",
            isMulti,
            defaultOptions
        }
    },
    {
        label: "District",
        inputProps: {
            isOptional,
            type: "select",
            name: 'district_id',
            value: init.district || [],
            placeholder: "District of Residence",
            options: [],
            isMulti,
            defaultOptions
        }
    },
    {
        label: "Languages",
        inputProps: {
            isOptional,
            type: "select",
            name: "mother_tongue_id",
            value: init.mother_tongue,
            placeholder: "Any Languages",
            defaultOptions,
            isMulti,
            options: partnervalues.languages
        }
    },
    {
        label: "Religion",
        inputProps: {
            isOptional,
            type: "select",
            value: Array.isArray(init.religion) && init.religion.length > 0 ?
                { value: init.religion[0].value, label: init.religion[0].label } : null,
            name: "religion_id",
            placeholder: "Any Religion",
            defaultOptions,
            options: partnervalues.religions,
            decendant: "caste_id",
            decendentOptionName: "castes",
        }
    },
    {
        label: "Caste",
        inputProps: {
            isOptional,
            type: "select",
            value: init.caste,
            isMulti,
            name: "caste_id",
            placeholder: "Caste",
            defaultOptions,
            options: [],
            decendant: "sub_caste_id",
            decendentOptionName: "sub_castes",
        }
    },
    {
        label: "Sub Caste",
        inputProps: {
            isOptional,
            type: "select",
            name: "sub_caste_id",
            value: init.sub_caste,
            placeholder: "Any Sub Caste",
            isMulti,
            defaultOptions,
            options: []
        }
    }
];

export const phyPrefernces = init =>  [
    {
        label: "Height",
        inputProps: {
            isOptional,
            type: "range",
            name: "height",
            unit: "cms",
            maxValue: 240,
            minValue: 120,
            mulitple: 10,
            value: {
                min: init.height_from,
                max: init.height_to,
            }
        }
    },
    // {
    //     label: "Physical Status",
    //     inputProps: { 
    //         type: "select",
    //         name: "physical_status",
    //         value: {value: init.id, label: "Does'nt Matter"},
    //         placeholder: "Any physical status",
    //         options: physically
    //     }
    // }
];

export const eduPreferences = init => [
    {
        label: "Education",
        inputProps: {
            isOptional,
            type: "select",
            name: "education_id",
            value: init.education,
            placeholder: "Any Education preferences",
            isMulti,
            defaultOptions: [{id: '', name: 'Choose any', courses: defaultOptions }],
            options: partnervalues.degrees,
            optgroup: 'courses'
        }
    },
    {
        label: "Employment Type",
        inputProps: {
            isOptional,
            type: "select",
            name: "employed_in_id",
            value: init.employed_in,
            placeholder: "Any Employment Type preferences",
            isMulti,
            options: partnervalues.employment,
            defaultOptions
        }
    },
    {
        label: "Occupation",
        inputProps: {
            isOptional,
            type: "select",
            name: "occupation_id",
            optgroup: 'occupation',
            defaultOptions: [{id: '', name: 'Choose any', occupation: defaultOptions }],
            value: init.occupation,
            placeholder: "Any Occupation",
            isMulti,
            options: partnervalues.careers
        }
    },
    {
        label: "Annual income",
        inputProps: {
            isOptional,
            type: "range",
            name: "income",
            unit: "Lakh (Rs)",
            maxValue: 15,
            minValue: 0,
            mulitple: 1,
            value: {
                min: init.income_from ? 
                Math.floor(init.income_from / 100000) : 0,
                max: init.income_to ? 
                Math.floor(init.income_to / 100000) : 0,
            }
        }
    },
];

export const astroPreferences = init => [
    {
        label: "Star",
        inputProps: {
            isOptional,
            type: "select",
            name: "star_id",
            value: init.star,
            placeholder: "Any Star",
            isMulti,
            defaultOptions,
            options: partnervalues.stars,
            // decendant: "raasi_id",
            // decendentOptionName: "raasi",
        }
    },
    // {
    //     label: "Raasi",
    //     inputProps: {
    //         isOptional,
    //         type: "select",
    //         name: "raasi_id",
    //         defaultOptions,
    //         value: init.raasi,
    //         placeholder: "Any Raasi",
    //         isMulti,
    //         options: []
    //     }
    // },
    {
        label: "Dosham",
        inputProps: {
            isOptional,
            name: "dosham_choices",
            type: "radioGroup",
            value: [ init.dosham_choices ],
            options: doshamChoices
        }
    },
    {
        label: "Choose Dosham",
        inputProps: {
            isOptional,
            type: init.dosham_choices === "Yes" ? "select" : "hidden",
            name: "dosham_id",
            value: init.dosham,
            placeholder: "Any Dosham",
            isMulti,
            options: partnervalues.dosham
        }
    }
];

export const habitPreferences = init => [
    {
        label: "Food Habits",
        inputProps: {
            isOptional,
            type: "select",
            name: "food_habits",
            value: { value: init.food_habits, label: init.food_habits },
            placeholder: "Any Food Habits",
            options: partnervalues.eating
        }
    },
    {
        label: "Smoking",
        inputProps: {
            isOptional,
            type: "select",
            name: "smoking_habits",
            value: { value: init.smoking_habits, label: init.smoking_habits },
            placeholder: "Any Smoking habits",
            options: partnervalues.drinking
        }
    },
    {
        label: "Drinking",
        inputProps: {
            isOptional,
            type: "select",
            name: "drinking_habits",
            value: { value: init.drinking_habits, label: init.drinking_habits },
            placeholder: "Any Drinking habits",
            options: partnervalues.drinking
        }
    }
];

//partner form details
export const partnerform = [
    {
        title: "Basic",
        description: "Get us to know. how you look. Your partners wants to know how you look",
        inputsName: 'partner_basic',
        formName: PARTNER_BASIC_INFO,
        initFunc: basicPrefrences
    },
    {
        title: "Physical Characteristics",
        description: "Get us to know. how you look. Your partners wants to know how you look",
        inputsName: 'partner_phy',
        formName: PARTNER_PHY,
        initFunc: phyPrefernces
    },
    {
        title: "Education & Occupation",
        description: "Get us to know. how you look. Your partners wants to know how you look",
        inputsName: 'partner_edu',
        formName: PARTNER_EDU,
        initFunc: eduPreferences
    },
    {
        title: "Astrological",
        description: "Get us to know. how you look. Your partners wants to know how you look",
        inputsName: 'partner_astro',
        formName: PARTNER_ASTRO,
        initFunc: astroPreferences
    },
    {
        title: "Habits",
        description: "Get us to know. how you look. Your partners wants to know how you look",
        inputsName: 'partner_habits',
        formName: PARTNER_HABITS,
        initFunc: habitPreferences
    }
];