import React from 'react'
import Heading from '../containers/Dashboard/heading';

export default function Pagelayout({children}) {
    return (
        <div className="pb-4">
            <Heading />
            {children}
        </div>
    )
}
