export const rZPay = (order, user, handleResponse) => {
    const { razorpay_api_key, amount_in_paisa, order_id } = order;
    const options = {
        "key": razorpay_api_key,
        "amount": amount_in_paisa, 
        "name": "Tharagar",
        "description": "Tharagar payment",
        "order_id": order_id,
        "currency": "INR",
        "image": "https://tharagar.s3.ap-south-1.amazonaws.com/Supported+Files/logo.svg",
        "handler": res => {
            handleResponse(res);
        },
        "prefill": {
            "name": user.first_name,
            "email": user.email,
            "contact": user.username 
        },
        "theme": {
            "color": "#ee0979"
        }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
}