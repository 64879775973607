import { issues } from "./mastervalues";

const choices = [
    {
        value: '0',
        label: "Private",
    },
    {
        value: '1',
        label: "Public"
    }
];

export const profSet = [
    {
        label: "Number privacy",
        inputProps: {
            type: "radioGroup",
            value: [],
            name: "is_number_public",
            options: choices
        }
    },
    {
        label: "Photo privacy",
        inputProps: {
            type: "radioGroup",
            name: "is_photo_public",
            value: [],
            options: choices     
        }
    }
];


export const changePassword = [
    {
        label: "Old password",
        inputProps: {
            type: "password",
            value: "",
            name: "old_password",
            placeholder: "Enter old password"
        }
    },
    {
        label: "New password",
        helpText: "Password must have minimum 6 characters",
        inputProps: {
            type: "password",
            name: "retype_password",
            value: "",
            placeholder: "Enter new password"  
        }
    },
    {
        label: "Retype password",
        inputProps: {
            type: "password",
            name: "new_password",
            value: "",
            placeholder: "Retype new password"  
        }
    }
];

export const resetPassword = [
    {
        label: "New password",
        inputProps: {
            type: "password",
            name: "retype_password",
            value: "",
            placeholder: "Enter new password"  
        }
    },
    {
        label: "Retype password",
        inputProps: {
            type: "password",
            name: "password",
            value: "",
            placeholder: "Retype new password"  
        }
    }
];

export const contactForm = [
    {
        inputProps: {
            type: "pureText",
            name: "name",
            value: "",
            placeholder: "Enter your name" ,
            maxLength: 60 
        }
    },
    {
        inputProps: {
            type: "email",
            name: "email",
            value: "",
            placeholder: "Enter your email"  
        }
    },
    {
        inputProps: {
            type: "number",
            name: "phone_number",
            value: "",
            placeholder: "Enter your phone number" ,
            maxLength: 12 
        }
    },
    {
        inputProps: {
            type: "select",
            name: "type",
            value: null,
            options: issues,
            placeholder: "Select issue type"  
        }
    },
    {
        inputProps: {
            type: "textarea",
            name: "descriptions",
            maxLength: 255,
            value: '',
            placeholder: "Enter your message",
            style: {
                height: `130px`,
                resize: `none`,
            } 
        }
    },
];


export const deacSet = [
    {
        label: "Reason",
        dependData: "reason",
        inputProps: {
            type: "radioGroup",
            value: ["Married"],
            name: "reason",
            options: [
                {
                    value: "Married",
                    label: "Married"
                },
                {
                    value: "Marriage Fixed",
                    label: "Marriage Fixed"
                },
                {
                    value: "Others",
                    label: "Others"
                },
            ]
        }
    },
    {
        label: "Other Reason",
        inputProps: {
            type: "hidden",
            name: "other_reason",
            value: null,
            options: [
                {
                    value: "Many calls from telecalling executives",
                    label: "Many calls from telecalling executives,"
                },
                {
                    value: "Prefer to search later",
                    label: "Prefer to search later"
                },
                {
                    value: "Not getting enough matches",
                    label: "Not getting enough matches"
                },
                {
                    value: "Any other reason",
                    label: "Any other reason"
                },
            ],
            placeholder: "Select reason"       
        }
    }
];



