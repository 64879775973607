import React, { Component } from 'react'
import { connect } from 'react-redux'
import ApplyCoupon from './applycoupon';
import List from '../../components/list';
import { Feature } from '../../components/upgrade/package';

class Order extends Component {

    componentDidMount() {
        if (!this.props.packageId) {
            this.el.style.display = "none";
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.packageId && this.props.packageId !== prevProps.packageId) {
            this.el.style.display = "block";
            this.el.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        const { packageId, packages } = this.props;
        let pack = null;
        if (packageId) {
            pack = packages.filter(x => x.id === packageId)[0];
        }
        return (
            <div
                className="container pt-3 pb-3 mb-5"
                ref={el => this.el = el}
            >
                <div className="pt-3 pb-3 text-center" >
                    <h3 className="mb-0">Order Summary</h3>
                    <p></p>
                </div>
                {packageId && pack && <div className="row">
                    <div className="col-md-12">
                        <div className="border p-md-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="border-right p-sm-2 h100p">
                                        <h5>Package Selected : {pack.name}</h5>
                                        <ul className="list-unstyled pl-0 pt-2">
                                            <Feature
                                                description={`${pack.validity} days validatity`}
                                                mb={1}
                                            />
                                            <Feature
                                                description={`${pack.view_count} phone number views`}
                                                mb={1}
                                            />
                                            <List
                                                RenderItem={Feature}
                                                list={pack.features}
                                                title={`selected`}
                                                mb={1}
                                            />
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <ApplyCoupon pack={pack} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = ({ list_packages }) => ({
    packageId: list_packages.chosenPackage,
    packages: list_packages.packages,
    addOns: list_packages.add_on
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Order)
