import React from 'react'
import Pagelayout from '../pagelayout';
import ManageTabs from './managetabs';
import { dashtabs } from '../../containers/Dashboard/matches';
import RouteTabs from '../Routes/routetabs';
import Promote from '../../containers/Dashboard/Promote';

const DashboardPage = () => (
    <Pagelayout>
        <Promote />
        <div className="container pt-3 pb-3">
            <div className="row">
                <div className="col-lg-3">
                    <div className="mb-3">
                        <ManageTabs />
                    </div>
                </div>
                <div className="col-lg-9">
                    <RouteTabs
                        title="manage" 
                        tabs={dashtabs}
                    />
                </div>
            </div>
        </div>
    </Pagelayout>
)

export default DashboardPage
