import { partnervalues } from "./mastervalues";
import {
    ADV_BASIC,
    ADV_EDU,
    ADV_ASTRO,
    ADV_HABITS,
    ADV_FILTER
} from "../../redux/actions/constants";

const isMulti = true;
const isOptional = true;

const doesnt = { value: `Doesn't matter`, label: `Doesn't matter` };

const physically = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    doesnt
];

const doshamChoices = [
    {
        value: "Yes",
        label: "Yes"
    },
    {
        value: "No",
        label: "No"
    },
    {
        value: `Doesn't matter`,
        label: `Doesn't matter`
    }
];

//partner preferences
export const basicSearch = [
    {
        label: "Age",
        inputProps: {
            type: "range",
            isOptional,
            name: "age",
            unit: "yrs",
            mulitple: 1,
            maxValue: 70,
            minValue: 18,
            value: {
                min: 18,
                max: 40
            }
        }
    },
    {
        label: "Height",
        inputProps: {
            type: "range",
            name: "height",
            isOptional,
            unit: "cms",
            maxValue: 240,
            minValue: 120,
            mulitple: 10,
            value: {
                min: 100,
                max: 300
            }
        }
    },
    {
        label: "Physically Challenged",
        inputProps: {
            type: "select",
            isOptional,
            name: "physical_status",
            value: doesnt,
            placeholder: "Select physical status",
            options: physically
        }
    },
    {
        label: "Martial Status",
        inputProps: {
            type: "select",
            isOptional,
            name: "marital_status",
            value: [],
            isMulti,
            placeholder: "Marital Status",
            options: partnervalues.marital_status
        }
    },
    {
        label: "Citizenship",
        inputProps: {
            type: "select",
            name: "citizenship_id",
            value: [],
            placeholder: "Country of Residence",
            options: partnervalues.country,
            decendant: "state_id",
            decendentOptionName: "states",
            isMulti,
            isOptional
        }
    },
    {
        label: "State",
        inputProps: {
            isOptional,
            type: "select",
            name: "state_id",
            value: [],
            placeholder: "State of Residence",
            options: [],
            decendant: "district_id",
            decendentOptionName: "district",
            isMulti
        }
    },
    {
        label: "District",
        inputProps: {
            isOptional,
            type: "select",
            name: "district_id",
            value: [],
            placeholder: "District of Residence",
            options: [],
            isMulti
        }
    },
    {
        label: "Religion",
        inputProps: {
            type: "select",
            isOptional,
            name: "religion",
            value: [],
            placeholder: "Select Religion",
            isMulti,
            options: partnervalues.religions,
            decendant: "caste",
            decendentOptionName: "castes"
        }
    },
    {
        label: "Caste",
        inputProps: {
            type: "select",
            isOptional,
            name: "caste",
            value: [],
            placeholder: "Select Caste",
            isMulti,
            options: [],
            decendant: "sub_caste",
            decendentOptionName: "sub_castes"
        }
    },
    {
        label: "Sub Caste",
        inputProps: {
            type: "select",
            isOptional,
            name: "sub_caste",
            value: [],
            placeholder: "Select Sub Caste",
            isMulti,
            options: []
        }
    }
];

export const eduSearch = [
    {
        label: "Education",
        inputProps: {
            type: "select",
            isOptional,
            name: "education",
            value: [],
            placeholder: "Select Education",
            isMulti,
            options: partnervalues.degrees,
            optgroup: "courses"
        }
    },
    {
        label: "Employment Type",
        inputProps: {
            type: "select",
            isOptional,
            name: "employment_in",
            value: [],
            placeholder: "Select Employment Type",
            isMulti,
            options: partnervalues.employment
        }
    },
    {
        label: "Select Occupation",
        inputProps: {
            type: "select",
            isOptional,
            name: "occupation",
            optgroup: "occupation",
            value: [],
            placeholder: "Select Occupation",
            isMulti,
            options: partnervalues.careers
        }
    }
];

export const astroSearch = [
    {
        label: "Star",
        inputProps: {
            type: "select",
            isOptional,
            name: "star",
            value: [],
            placeholder: "Select Star",
            isMulti,
            options: partnervalues.stars,
            decendant: "raasi",
            decendentOptionName: "raasi"
        }
    },
    {
        label: "Raasi",
        inputProps: {
            type: "select",
            isOptional,
            name: "raasi",
            value: [],
            placeholder: "Select Raasi",
            isMulti,
            options: []
        }
    },
    {
        label: "Dosham",
        inputProps: {
            isOptional,
            name: "dosham_choices",
            type: "radioGroup",
            value: [`Doesn't matter`],
            options: doshamChoices
        }
    },
    {
        label: "Choose Dosham",
        inputProps: {
            isOptional,
            type: "hidden",
            name: "dosham_id",
            value: [],
            placeholder: "Any Dosham",
            isMulti,
            options: partnervalues.dosham
        }
    }
];

export const habitSearch = [
    {
        label: "Food Habits",
        inputProps: {
            type: "select",
            isOptional,
            name: "food_habits",
            value: doesnt,
            placeholder: "Select Food Habits",
            options: partnervalues.eating
        }
    },
    {
        label: "Smoking",
        inputProps: {
            type: "select",
            isOptional,
            name: "smoking_habits",
            value: doesnt,
            placeholder: "Select Smoking habits",
            options: partnervalues.drinking
        }
    },
    {
        label: "Drinking",
        inputProps: {
            type: "select",
            isOptional,
            name: "drinking_habits",
            value: doesnt,
            placeholder: "Select Drinking habits",
            options: partnervalues.drinking
        }
    }
];

export const filters = [
    {
        label: "Show Profile",
        inputProps: {
            type: "radioGroup",
            value: ["0"],
            isOptional,
            name: "show_profile",
            options: [
                {
                    value: "0",
                    label: "All profiles"
                },
                {
                    value: "1",
                    label: "With photo"
                }
            ]
        }
    },
    {
        label: "Don't show Profile",
        inputProps: {
            type: "radioGroup",
            isOptional,
            name: "reject_profile",
            value: [],
            isMulti,
            options: [
                {
                    value: "3",
                    label: "Ignored"
                },
                {
                    value: "1",
                    label: "Contacted"
                },
                {
                    value: "2",
                    label: "Viewed"
                },
                {
                    value: "4",
                    label: "Shorlisted"
                }
            ]
        }
    }
];

export const advform = [
    {
        title: "Basic",
        fullWidth: true,
        inputsName: "basic_advsearch",
        formName: ADV_BASIC
    },
    {
        title: "Education & occupation",
        fullWidth: true,
        inputsName: "edu_advsearch",
        formName: ADV_EDU
    },
    {
        title: "Astrological",
        fullWidth: true,
        inputsName: "astro_advsearch",
        formName: ADV_ASTRO
    },
    {
        title: "Habits",
        fullWidth: true,
        inputsName: "habit_advsearch",
        formName: ADV_HABITS
    },
    {
        title: "Filters",
        fullWidth: true,
        inputsName: "filter_advsearch",
        formName: ADV_FILTER
    }
];
