import React from 'react'
import LoadDataHoc from '../../hocs/loaddatahoc';
import { RECENT_MATCHES, recentjoin_url } from '../../redux/actions/constants';
import DynamicList from '../../components/dynamiclist';
import List from '../../components/list';
import Matches, { MatchLoader } from '../../components/Dashboard/matches';

const createNarray = (n) => Array.apply(null, { length: n }).map(Function.call, Number);

const RecLdr = () => (
    <List 
        title="recentldr"
        RenderItem={() => (
            <div className="col-md-4">
                <MatchLoader />
            </div>
        )}
        list={createNarray(9)}
    />
)

const RecentlyJoined = ({apiData}) => {
    return <DynamicList 
        RenderItem={(props) => (
            <div 
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
                className={`col-md-4`}
            >
                <Matches hideFunc {...props} /> 
            </div>
        )}
        {...apiData}
        Loader={RecLdr}
        title="recentlyjoined"
    />
}

export default LoadDataHoc(RecentlyJoined, RECENT_MATCHES, "recent_matches", recentjoin_url);
