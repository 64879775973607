import React, { Fragment } from "react";
import RejectBtn from "./rejectbtn";
import ShortListBtn from "./shortlistbtn";
import { OpenModalBtn } from "../Modal/closemodal";
import phoneicon from "../../assets/img/func/smartphone.png";
import AcceptBtn from "./acceptbtn";
import Startchat from "./startchat";

const MatchFunc = props => {
    const {
        id,
        is_shortlist,
        is_ignore,
        is_number_public,
        send_id,
        type,
        status,
        activeID,
        baseId,
        user
    } = props;
    if (user.is_active === false) {
        return (
            <div className="border-top f-14 text-danger p-2 text-center">
                <i className="fas fa-ban mr-1"></i> Profile is currently
                unavailable
            </div>
        );
    } else {
        return (
            <div className="match-func border-top">
                <div className="match-func-item border-right">
                    <Startchat chatUser={props} />
                </div>
                {send_id && type ? (
                    <Fragment>
                        <div className="match-func-item border-right">
                            <AcceptBtn
                                activeID={activeID}
                                id={send_id}
                                type={type}
                                status={status}
                                is_accept={true}
                            />
                        </div>
                        <div className="match-func-item border-right">
                            <AcceptBtn
                                activeID={activeID}
                                id={send_id}
                                type={type}
                                status={status}
                                is_accept={false}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="match-func-item border-right">
                            <ShortListBtn
                                baseId={baseId}
                                activeID={activeID}
                                id={id}
                                is_shortlist={is_shortlist}
                            />
                        </div>
                        <div className="match-func-item border-right">
                            <RejectBtn
                                baseId={baseId}
                                activeID={activeID}
                                id={id}
                                is_ignore={is_ignore}
                            />
                        </div>
                    </Fragment>
                )}
                <div className="match-func-item">
                    <OpenModalBtn
                        modalName="PHONE_MODAL"
                        modalProps={props}
                        className="btn match-func-btn"
                    >
                        <img src={phoneicon} alt="" />
                        <p>
                            {is_number_public
                                ? `View phone no.`
                                : `Request no.`}
                        </p>
                    </OpenModalBtn>
                </div>
            </div>
        );
    }
};

export default MatchFunc;
