import React from 'react'
import Content from './content';
import Section from '../section';
import PageHeading from './pageheading';

const SafeSecure = () => {
    return (
        <div>
            <PageHeading heading="Safety & Security" />
            <Section className="pt-4">
                <div className="col-md-12">
                    <Content heading="Safe and Secure Matrimonial Website">
                        <p>The safety and security of our registered members is of paramount importance to us. Tharagar.com follows stringent confidentiality measures to safeguard the privacy of our members. As a registered member you are provided with complete control to manage and protect your privacy.
Our customer services team conducts rigorous checks to ensure that only genuine profiles are activated throughout our matrimonial website. Even the photos that are uploaded are pre-screened and approved before they go live. Tharagar.com will never pass on your data to a third party without your agreement. The payment system is protected by SSL-encryption, to ensure that your payment data is safely transmitted.
We know that you are looking for a lifetime commitment; hence we make sure that all our registered profiles are thoroughly checked and phone verified to safeguard your experience. As a registered member of our matrimonial website you are given complete control to decide the way you want to utilize our services.
To ensure safe and secure usage, here are some tips that you can follow:</p>
                    </Content>
                    <Content heading="Safety">
                        <ol>
                            <li>Don't give out personal contact details immediately. Remember you are the one in control.</li>
                            <li>Take your time to learn more about each potential partner and to feel comfortable about communicating with him/her. It is for you to decide if and when you want to take things further.</li>
                            <li>You may wish to set up an email account exclusively for activities connected to your search for a partner. Free, web-based email services like Hotmail are ideal for this - and you don't have to reveal your name in the email address.</li>
                            <li>If anything makes you feel uncomfortable about another member, stop contacting them. You can delete their profile instantly. If you feel someone is abusing the service, tell us immediately.</li>
                            <li>Always use your common sense and remember you are the one in control.</li>
                        </ol>
                    </Content>
                    <Content heading="Security">
                        <ol>
                            <li>Meet the person on a neutral ground- in a busy public place that you know well.</li>
                            <li>Don't arrange for the other member to pick you up from your home.</li>
                            <li>Don't let the other member make arrangements for your travel or for any hotel accommodation.</li>
                            <li>Keep a trustworthy friend or relative informed and on call.</li>
                            <ol>
                                <li>Let this friend or relative know where you are going and what time you are expected home.</li>
                                <li>Supply your friend/relative with details of the person you are going to meet.</li>
                                <li>At a suitable point, call your friend/relative to let them know that things are OK.</li>
                            </ol>
                            <li>Consider going on a double date with a friend/friends, or going out with a group. The group can include people you know and people your date knows.</li>
                            <li>You will probably feel that you know someone through email and phone contact, but remember that they are still a stranger when you first meet face to face.</li>
                            <li>Don't accept a lift home.</li>
                            <li>Always have a contingency plan in place. If something unexpected happens, make sure you can leave quickly and easily and that there are people close by who can help you if necessary.</li>
                            <li>Take a mobile phone with you. Make sure the phone is fully charged and working. You can leave the phone on during the date if you wish.</li>
                            <li>It can take time to get to know someone properly. If a person is truly interested in you, they won't mind if you want to take your time to develop a relationship.</li>
                            <li>Always use your common sense, keep in control and don't be forced into doing something you wouldn't normally do.</li>
                        </ol>
                    </Content>
                    <br></br>
                </div>
            </Section>
        </div>  
    )
}

export default SafeSecure
