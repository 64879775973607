import React from "react";
import { Link } from "react-router-dom";
import { EDITUSER_BASIC_INFO, EDITUSER_PHY, EDITUSER_EDU, EDITUSER_ASTRO, EDITUSER_HABITS, EDITUSER_FAMILY, EDITUSER_DESCRIPTION } from "../../redux/actions/constants";
import { profilevalues } from "./mastervalues";
import { subYears } from "../dateformat";
// import { profileDepends } from "./depends";

const doshamChoices = [
    {
        value: '1',
        label: "Yes",
    },
    {
        value: '0',
        label: "No"
    }
];

//lakhs label
const lakhsLabel = val => `${val} Lakh (Rs)`

///annual income in lakhs
let annualIncome = [];
for (let i = 0; i <= 15; i++) {
    annualIncome.push({
        value: i * 100000,
        label: lakhsLabel(i)
    })
}

export const init = {
    "user": {
        "first_name": "",
        "last_name": "",
    },
    "country": null,
    "gender": null,
    "state": null,
    "district": null,
    "city": null,
    "date_of_birth": "",
    "about_me": "",
    "mother_tongue": null,
    "user_details": {
        "marital_status": null,
        "number_of_children": '',
        "height": null,
        "is_physically_challenged": false,
        "weight": '',
        "body_type": null,
        "skin_tone": null,
        "family_status": null,
        "no_of_brother": "",
        "no_of_brother_married": "",
        "no_of_sister": "",
        "no_of_sister_married": "",
        "family_type": null,
        "family_values": null,
        "food_habits": null,
        "smoking_habits": null,
        "drinking_habits": null
    },
    "religion_details": {
        "religion": null,
        "caste": null,
        "sub_caste": null,
        "dosham": [],
        "dosham_choices": false,
        "raasi": null,
        "star": null,
        "gothram": "",
    },
    "education_details": {
        "highest_education": null,
        "employed_in": null,
        "occupation": null,
        "annual_income": null
    }
};

export const helpJSX = (disabled) => disabled ? (
    <React.Fragment>
        This field has reached maximum changes limit. Please contact customer support to make changes.
        <Link to={"/help"}> Go to help page</Link>
    </React.Fragment>
) : "This field cannot be changed more than 2 times";

//basic information
export const basicInfo = (init, disabledIps) => {
    return [
        {
            label: "Full Name",
            inputProps: {
                type: "pureText",
                name: 'first_name',
                value: init.user.first_name || '',
                placeholder: "Full Name"
            }
        },
        // {
        //     label: "Last Name",
        //     inputProps: {
        //         type: "pureText",
        //         name: 'last_name',
        //         value: init.user.last_name || '',
        //         placeholder: "Last Name"
        //     }
        // },
        {
            label: "Mother Tongue",
            inputProps: {
                type: "select",
                name: 'mother_tongue_id',
                value: init.mother_tongue,
                options: profilevalues.languages,
                placeholder: "Mother tongue"
            }
        },
        {
            label: "Date of birth",
            helpText: helpJSX(disabledIps.includes("date_of_birth")),
            inputProps: {
                type: "date",
                name: 'date_of_birth',
                value: init.date_of_birth,
                dateFormat: 'dd/MM/yyyy',
                maxDate: subYears(init.gender === "Male" ? 21 : 18),
                placeholderText: "DD/MM/YYYY",
                disabled: disabledIps.includes("date_of_birth")
            }
        },
        {
            label: "Martial Status",
            dependData: "martial",
            inputProps: {
                type: "select",
                name: "marital_status_id",
                value: init.user_details.marital_status,
                placeholder: "Marital Status",
                options: profilevalues.marital_status
            }
        },
        {
            label: "Number of children",
            inputProps: {
                type: "hidden",
                name: "number_of_children",
                maxLength: 2,
                value: init.user_details.number_of_children || '',
                placeholder: "Number of children"
            }
        },
        {
            label: "Country",
            inputProps: {
                type: "select",
                name: 'country_id',
                value: init.country,
                placeholder: "Country of Residence",
                options: profilevalues.country,
                decendant: "state_id",
                decendentOptionName: "states",
            }
        },
        {
            label: "State",
            inputProps: {
                type: "select",
                name: "state_id",
                value: init.state,
                placeholder: "State of Residence",
                options: [],
                decendant: "district_id",
                decendentOptionName: "district",
            }
        },
        {
            label: "District",
            inputProps: {
                type: "select",
                name: 'district_id',
                value: init.district,
                placeholder: "District of Residence",
                options: [],
                decendant: "city_id",
                decendentOptionName: "cities",
            }
        },
        {
            label: "City",
            inputProps: {
                type: "select",
                name: 'city_id',
                value: init.city,
                placeholder: "City of Residence",
                options: [],
                isOptional: true,
            }
        },
        {
            label: "Religion",
            dependData: "relSubCaste",
            helpText: helpJSX(disabledIps.includes("religion")),
            inputProps: {
                type: "select",
                value: init.religion_details.religion,
                name: "religion_id",
                placeholder: "Select Religion",
                options: profilevalues.religions,
                decendant: "caste_id",
                decendentOptionName: "castes",
                isDisabled: disabledIps.includes("religion")
            }
        },
        {
            label: "Caste",
            dependData: "caste",
            helpText: helpJSX(disabledIps.includes("caste")),
            inputProps: {
                type: "select",
                name: "caste_id",
                value: init.religion_details.caste,
                placeholder: "Caste",
                options: [],
                decendant: "sub_caste_id",
                decendentOptionName: "sub_castes",
                isDisabled: disabledIps.includes("caste")
            }
        },
        {
            label: "Sub Caste",
            inputProps: {
                type: "select",
                name: 'sub_caste_id',
                value: init.religion_details.sub_caste,
                options: [],
                placeholder: "Sub caste",
                isOptional: init.religion_details.religion && init.religion_details.religion.value !== 1
            }
        },
        {
            label: "Gothram",
            inputProps: {
                type: "pureText",
                name: "gothram",
                value: init.religion_details.gothram,
                placeholder: "Gothram",
                isOptional: true
            }
        },
    ];
} 

//physical characteristics
export const phyChar = init => [
    {
        label: "Height",
        inputProps: {
            type: "select",
            value: init.user_details.height,
            name: 'height_id',
            options: profilevalues.height,
            placeholder: "Height"
        }
    },
    {
        label: "Weight",
        inputProps: {
            type: "number",
            maxLength: 3,
            name: 'weight',
            value: init.user_details.weight || '',
            placeholder: "Weight in kgs"
        }
    },
    {
        label: "Body Type",
        inputProps: {
            type: "select",
            name: 'body_type_id',
            value: init.user_details.body_type,
            options: profilevalues.body_types,
            placeholder: "Body Type"
        }
    },
    {
        label: "Skin Tone",
        inputProps: {
            type: "select",
            name: 'skin_tone_id',
            value: init.user_details.skin_tone,
            options: profilevalues.skin_tone,
            placeholder: "Skin Tone"
        }
    },
    {
        label: "Physical Status",
        inputProps: {
            type: "checkbox",
            value: 'is_physically_challenged',
            name: "is_physically_challenged",
            checklabel: ["Physically challenged"],
            checked: init.user_details.is_physically_challenged
        }
    }
];

//education and occupation
export const eduOcc = init => [
    {
        label: "Highest Education",
        inputProps: {
            type: "select",
            name: 'highest_education_id',
            value: init.education_details.highest_education,
            placeholder: "Highest Education",
            options: profilevalues.degrees,
            optgroup: 'courses'
        }
    },
    {
        label: "Employment Type",
        dependData: "employ",
        inputProps: {
            type: "select",
            name: 'employed_in_id',
            value: init.education_details.employed_in,
            placeholder: "Employment Type",
            options: profilevalues.employment
        }
    },
    {
        label: "Occupation",
        dependData: "income",
        inputProps: {
            type: "select",
            name: 'occupation_id',
            value: init.education_details.occupation,
            placeholder: "Occupation",
            optgroup: 'occupation',
            options: profilevalues.careers
        }
    },
    {
        label: "Annual Income",
        inputProps: {
            type: "select",
            name: 'annual_income',
            options: annualIncome,
            isOptional: true,
            //convert to lakhs
            value: init.education_details.annual_income ? { 
                value: Math.floor(init.education_details.annual_income / 100000) * 100000,  
                label: lakhsLabel(Math.floor(init.education_details.annual_income / 100000))
            } : null,
            /////
            placeholder: "Annual Income"
        }
    }
];

//astrological info
export const astro = init => [
    {
        label: "Star",
        inputProps: {
            type: "select",
            name: 'star_id',
            value: init.religion_details.star,
            placeholder: "Star",
            options: profilevalues.stars,
            decendant: "raasi_id",
            decendentOptionName: "raasi",
            isOptional: init.religion_details.religion && init.religion_details.religion.value !== 1
        }
    },
    {
        label: "Raasi",
        inputProps: {
            type: "select",
            name: 'raasi_id',
            value: init.religion_details.raasi,
            placeholder: "Raasi",
            options: [],
            isOptional: init.religion_details.religion && init.religion_details.religion.value !== 1
        }
    },
    {
        label: "Dosham",
        dependData: "dosham",
        inputProps: {
            name: "dosham_choices",
            type: "radioGroup",
            value: [
                init.religion_details.dosham_choices ?
                    doshamChoices[0].value // yes
                    : doshamChoices[1].value //no
            ],
            options: doshamChoices,
            isOptional: init.religion_details.religion && init.religion_details.religion.value !== 1
        }
    },
    {
        label: "Choose Dosham",
        inputProps: {
            name: 'dosham_id',
            type: "hidden",
            value: init.religion_details.dosham && Array.isArray(init.religion_details.dosham) ?
                init.religion_details.dosham.reduce((a, c) => [...a, c.value], []) : [],
            isMulti: true,
            options: profilevalues.dosham,
            labelKey: "name",
            valueKey: "id"
        }
    },
];

//habits
export const habits = init => [
    {
        label: "Food Habits",
        inputProps: {
            type: "select",
            name: "food_habits",
            value: init.user_details.food_habits ?
                { value: init.user_details.food_habits, label: init.user_details.food_habits } : null,
            placeholder: "Food Habits",
            options: profilevalues.eating
        }
    },
    {
        label: "Smoking",
        inputProps: {
            type: "select",
            name: "smoking_habits",
            value: init.user_details.smoking_habits ?
                { value: init.user_details.smoking_habits, label: init.user_details.smoking_habits } : null,
            placeholder: "Smoking",
            options: profilevalues.drinking
        }
    },
    {
        label: "Drinking",
        inputProps: {
            type: "select",
            name: "drinking_habits",
            value: init.user_details.drinking_habits ?
                { value: init.user_details.drinking_habits, label: init.user_details.drinking_habits } : null,
            placeholder: "Drinking",
            options: profilevalues.drinking
        }
    },
];

//family profile
export const family = init => [
    {
        label: "Family Status",
        inputProps: {
            type: "select",
            name: 'family_status',
            value: init.user_details.family_status ?
                { value: init.user_details.family_status, label: init.user_details.family_status } : null,
            placeholder: "Family Status",
            options: profilevalues.familstatus
        }
    },
    {
        label: "Family Type",
        inputProps: {
            type: "select",
            name: "family_type",
            value: init.user_details.family_type ?
                { value: init.user_details.family_type, label: init.user_details.family_type } : null,
            placeholder: "Family Type",
            options: profilevalues.familtypes
        }
    },
    {
        label: "Family Values",
        inputProps: {
            type: "select",
            name: "family_values",
            value: init.user_details.family_values ?
                { value: init.user_details.family_values, label: init.user_details.family_values } : null,
            placeholder: "Family Values",
            options: profilevalues.familyvalues
        }
    },
    {
        label: "No. of Brothers",
        dependData: "bothers",
        inputProps: {
            type: "number",
            name: "no_of_brother",
            maxLength: 2,
            value: init.user_details.no_of_brother,
            placeholder: "Number of Brothers",
            isOptional: true
        }
    },
    {
        label: "No. of Brothers Married",
        inputProps: {
            type: "hidden",
            name: "no_of_brother_married",
            maxLength: 2,
            value: init.user_details.no_of_brother_married,
            placeholder: "Number of Brothers Married",
            isOptional: true
        }
    },
    {
        label: "No. of Sisters",
        dependData: "sisters",
        inputProps: {
            type: "number",
            name: "no_of_sister",
            maxLength: 2,
            value: init.user_details.no_of_sister,
            placeholder: "Number of Sisters",
            isOptional: true
        }
    },
    {
        label: "No. of Sisters Married",
        inputProps: {
            type: "hidden",
            name: "no_of_sister_married",
            maxLength: 2,
            value: init.user_details.no_of_sister_married,
            placeholder: "Number of Sisters Married",
            isOptional: true
        }
    },
];

// Description
export const desc = init => [
    {
        inputProps: {
            type: "textarea",
            name: "about_me",
            maxLength: 255,
            value: init.about_me || '',
            placeholder: "About yourself",
            style: {
                height: `130px`,
                resize: `none`,
                padding: `26px`
            }
        }
    }
];


export const descProps = {
    title: "Description",
    slug: "about-me",
    inputsName: 'edituser_desc',
    formName: EDITUSER_DESCRIPTION,
    initFunc: desc
};

export const edituserform = [
    {
        title: "Basic Information",
        slug: "basic-information",
        inputsName: 'edituser_basic',
        formName: EDITUSER_BASIC_INFO,
        initFunc: basicInfo
    },
    {
        title: "Physical Characteristics",
        slug: "physical-characteristics",
        inputsName: 'edituser_phy',
        formName: EDITUSER_PHY,
        initFunc: phyChar
    },
    {
        title: "Education & Occupation",
        slug: "education-and-occupation",
        inputsName: 'edituser_edu',
        formName: EDITUSER_EDU,
        initFunc: eduOcc
    },
    {
        title: "Astrological Information",
        slug: "astrological-information",
        inputsName: 'edituser_astro',
        formName: EDITUSER_ASTRO,
        initFunc: astro
    },
    {
        title: "Habits",
        slug: "habits",
        inputsName: 'edituser_habits',
        formName: EDITUSER_HABITS,
        initFunc: habits
    },
    {
        title: "Family Profile",
        slug: "family-profile",
        inputsName: 'edituser_family',
        formName: EDITUSER_FAMILY,
        initFunc: family
    },
    // descProps
];
