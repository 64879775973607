import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProfileDetails from '../../components/Profile/profiledetails';
import Gallery from '../../components/Profile/gallery';
import { myProfile } from '../../redux/actions/user';
import PartnerPrefers from '../../components/Profile/partnerprefers';
import Loadprofile from './loadprofile';
import ProfileImage from '../../components/Profile/ProfileImage';
import CustomBtn from '../../components/Buttons/CustomBtn';

class UserProfile extends Component {
    render() {
        const { photos, profile_pic, partner_preferences } = this.props.userData;
        const prefers = partner_preferences || {};
        return (
            <Loadprofile>
                <div className="container-fluid profile-page pt-3">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="sticky-dash-nav">
                                <ProfileImage
                                    photos={photos}
                                    profile_pic={profile_pic}
                                />
                                {
                                    photos && Array.isArray(photos) && photos.length > 0 ?
                                        <Gallery edit={true} images={photos} /> :
                                        <CustomBtn className="btn-block" children="Upload Photos" to={"/manage/photos"} />
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ProfileDetails edit={true} userData={this.props.userData} />
                        </div>
                        <div className="col-lg-3">
                            <PartnerPrefers {...prefers} />
                        </div>
                    </div>
                </div>
            </Loadprofile>
        )
    }
}

const mapStateToProps = ({ user, my_profile }) => ({
    userData: user, my_profile
})

const mapDispatchToProps = {
    myProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile) 
