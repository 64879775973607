import React from 'react'
import { SearchRecom } from '../../containers/Dashboard/matches';

const Recomended = ({limit = 3}) => {
    return (
        <div className="col-lg-4">
            <h6>Recomended Matches</h6>
            <SearchRecom noNext={`&limit=${limit}`} />
        </div>
    )
}

export default Recomended
