import React from 'react'
import Center from '../center';
import { OpenModalBtn } from '../../containers/Modal/closemodal';
import { deletePhoto, setProPic } from '../../redux/actions/user';
import { DELETE_PHOTOS, SET_PRO_PIC } from '../../redux/actions/constants';

const UploadPhoto = ({ id, image, is_approved, is_profile_pic }) => {
    return (
        <div className="col-md-4 col-sm-6 col-12 mb-3">
            <div className="relative border">
                {   image && !is_approved && !is_profile_pic &&
                    <div className="ribbon">Pending Approval</div>
                }
                {   image && is_profile_pic && is_approved &&
                    <div className="ribbon bg-success">Profile pic</div>
                }
                <Center className="upload-photo-top">
                    {id && image ?
                        <img className="w-100" src={image} alt="" /> :
                        <p className="m-0 f-22"><i className="fas fa-camera"></i></p>
                    }
                </Center>
                <div className="d-flex">
                    {
                        id ?
                            <React.Fragment>
                                {
                                    is_approved &&
                                    !is_profile_pic &&
                                    <OpenModalBtn
                                        className="btn flex-grow-1 br-0 custom-btn f-12"
                                        modalName="CONFIRM_MODAL"
                                        modalProps={{
                                            msg: `Are you sure you set this photo as profile pic?`,
                                            confirmProps: {
                                                reducerName: "set_profile_pic",
                                                ntAction: () => setProPic(id),
                                                formName: SET_PRO_PIC
                                            }
                                        }}
                                    >
                                        Set as profile pic
                                    </OpenModalBtn>
                                }
                                <OpenModalBtn
                                    className="btn flex-grow-1 br-0 custom-btn f-12"
                                    modalName="CONFIRM_MODAL"
                                    modalProps={{
                                        msg: `Are you sure you want delete this photo ?`,
                                        confirmProps: {
                                            reducerName: "delete_photo",
                                            ntAction: () => deletePhoto(id),
                                            formName: DELETE_PHOTOS
                                        }
                                    }}
                                >
                                    Delete
                                </OpenModalBtn>
                            </React.Fragment> :
                            <OpenModalBtn
                                className="btn flex-grow-1 br-0 custom-btn f-12"
                                modalName="CROP_IMAGE_MODAL"
                            >
                                Add Photo
                            </OpenModalBtn>
                    }
                </div>
            </div>
        </div>
    )
}

export default UploadPhoto
