import React from 'react'
import CustomBtn from '../../components/Buttons/CustomBtn';
import { connect } from 'react-redux'
import { choosePackage } from '../../redux/actions/upgrade';

const ChoosePackage = ({ id, packageId, choosePackage }) => {
    const isSelected = packageId === id;
    return (
        <CustomBtn
            // className={isSelected ? `btn-primary` : `` }
            onClick={() => choosePackage(id)}
        >
            { isSelected ? `Choosen Package` : `Upgrade Now` }
        </CustomBtn>
    )
}

const mapStateToProps = ({ list_packages }) => ({
    packageId: list_packages.chosenPackage
})

const mapDispatchToProps = {
    choosePackage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePackage)
