import React from 'react'
import AdvForm from '../../containers/Search/advsearchform';
import Recomended from './recommed';

const AdvSearch = () => {
    return (
        <div className="row mt-4">
            <AdvForm />
            <Recomended limit={8} />
        </div>
    )
}

export default AdvSearch
