import React from 'react'
import Section from '../section';
import CustomBtn from '../Buttons/CustomBtn';
import ic1 from "../../assets/img/homegallery/tharagar-info-1.jpg";
import ic2 from "../../assets/img/homegallery/tharagar-info-2.jpg";
import ic3 from "../../assets/img/homegallery/tharagar-info-3.jpg";

const Info = () => (
    <Section className="landing-section-pad">
        <div className="col-md-12 pb-5 mb-4">
            <h1 className="text-center mb-1">Best Free Matrimonial Service</h1>
            <p className="text-center mb-1">No Hidden Charges</p>
            <p className="text-center mb-1">Provide a pleasant, satisfying, and superior matchmaking experience</p>
            <div className="divider div-transparent div-dot"></div>
        </div>
        <div className="col-md-12">
            <div className="row align-items-center mb-50">
                <div className="col-md-6">
                    <img className="w-100 br-15" src={ic1} alt="" />
                </div>
                <div className="col-md-6">
                    <div className="pt-3 pb-3">
                        <h3>Register for free</h3>
                        <div className="f-20">
                            <ul className="list-unstyled pl-3">
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Register your profile for Free</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">View unlimited profiles</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Select & Connect with matches</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Send unlimited messages</span>
                                </li>
                            </ul>
                        </div>
                        <CustomBtn onClick={() => {
                            window.scrollTo(0, 0);
                        }}>
                            Sign Up
                        </CustomBtn>
                    </div>
                </div>
            </div>
            <div className="row d-flex align-items-center mb-50">
                <div className="col-md-6 orderMd2">
                    <div className="pt-3 pb-3">
                        <h3>Security & Privacy</h3>
                        <div className="f-20">
                            <ul className="list-unstyled pl-3">
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Hide your contact details</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Protect your photos</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">100% verified mobile numbers</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Secured online payment</span>
                                </li>
                            </ul>
                        </div>
                        <CustomBtn onClick={() => {
                            window.scrollTo(0, 0);
                        }}>
                            Sign Up
                        </CustomBtn>
                    </div>
                </div>
                <div className="col-md-6">
                    <img className="w-100 br-15" src={ic2} alt="" />
                </div>
            </div>
            <div className="row align-items-center mb-50">
                <div className="col-md-6">
                    <img className="w-100 br-15" src={ic3} alt="" />
                </div>
                <div className="col-md-6">
                    <div className="pt-3 pb-3">
                        <h3>Why Tharagar.com?</h3>
                        <div className="f-20">
                            <ul className="list-unstyled pl-3">
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Private, Secure and Confidential</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">User Friendly Technology</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">Intelligent Matchmaking</span>
                                </li>
                                <li>
                                    <i className="fas logo-red-color fa-check-square" aria-hidden="true"></i>
                                    <span className="pl-2">No Hidden Charges</span>
                                </li>
                            </ul>
                        </div>
                        <CustomBtn onClick={() => {
                            window.scrollTo(0, 0);
                        }}>
                            Sign Up
                        </CustomBtn>
                    </div>
                </div>
            </div>
        </div>
    </Section>
)

export default Info
