import React from 'react'
import SignInForm from '../../containers/Landing/loginform';
import { OpenModalBtn } from '../../containers/Modal/closemodal';
import "../../assets/css/signin.css";

const SiginForm = () => (
    <React.Fragment>
        <div className="header-signIn">
            <SignInForm />
        </div>
        <div className="signInMbtn">
            <OpenModalBtn 
                className="btn btn-outline-req f-14"
                modalName="SIGNIN_MODAL"
            >
                Sign In
            </OpenModalBtn>
        </div>
    </React.Fragment>
)

export default SiginForm
