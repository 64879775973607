import React from 'react'
import Center from '../center';
import List from '../list';
import ChoosePackage from '../../containers/upgrade/choosepackage';

export const Feature = ({ description, mt=3 }) => (
    <li className={`mt-${mt} mb-3`}>
        <span className="logo-blue-color mr-2 mt-1">
            <i className="fas fa-check"></i>
        </span>
        {description}
    </li>
)

const Package = ({ id, discount_price, original_price, name, validity, view_count, features, is_purchased }) => {
    return (
        <div className="col-md-4 flex-grow-1 mt-3 mb-2">
            <div className={`match ${is_purchased ? " purchasedpackage" : ""}`}>
                <div className="bg-light p-4 text-center">
                    <h5>{name}</h5>
                    {original_price && <p className="logo-red-color text-striked mt-3 mb-0">Rs {original_price}</p>}
                    <h1 className={`mb-0`}>
                        Rs {discount_price}
                    </h1>
                    <h6 className="mb-3">{validity} days</h6>
                    <p>for {view_count} phone number views</p>
                </div>
                <div className="d-flex justify-content-center" style={{ minHeight: `180px` }}>
                    <ul className="h100p list-unstyled m-0 pl-0">
                        <List
                            RenderItem={Feature}
                            list={features}
                            title={name + "-" + id}
                        />
                    </ul>
                </div>
                <Center className="pb-4">
                    <ChoosePackage id={id} />
                </Center>
            </div>
        </div>
    )
}

export default Package
